// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html {
    height: 100%; 
    overflow-x: hidden; 
    margin: 0;
  }
  
  body {
    margin: 0;
    padding: 0;
    min-height: 100vh; 
    width: 100%; 
    background: linear-gradient(109deg, #DDD5FC 0%, #F4E1DD 100%);
    background-attachment: fixed; 
   
   
  }
  
    
  #root, .app {
    display: flex;
    flex-direction: column;
    min-height: 100vh; 
  }
  
  .content {
    flex: 1 1; 
    padding-bottom: 50px;
  }
  
  .flex{
    display: flex;
  }
  
  
  
  `, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,kBAAkB;IAClB,SAAS;EACX;;EAEA;IACE,SAAS;IACT,UAAU;IACV,iBAAiB;IACjB,WAAW;IACX,6DAA6D;IAC7D,4BAA4B;;;EAG9B;;;EAGA;IACE,aAAa;IACb,sBAAsB;IACtB,iBAAiB;EACnB;;EAEA;IACE,SAAO;IACP,oBAAoB;EACtB;;EAEA;IACE,aAAa;EACf","sourcesContent":["html {\n    height: 100%; \n    overflow-x: hidden; \n    margin: 0;\n  }\n  \n  body {\n    margin: 0;\n    padding: 0;\n    min-height: 100vh; \n    width: 100%; \n    background: linear-gradient(109deg, #DDD5FC 0%, #F4E1DD 100%);\n    background-attachment: fixed; \n   \n   \n  }\n  \n    \n  #root, .app {\n    display: flex;\n    flex-direction: column;\n    min-height: 100vh; \n  }\n  \n  .content {\n    flex: 1; \n    padding-bottom: 50px;\n  }\n  \n  .flex{\n    display: flex;\n  }\n  \n  \n  \n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
