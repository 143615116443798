import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; 
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './Super_Admin_Login_Form.css';
import { FidgetSpinner } from 'react-loader-spinner';
import { FaEye, FaEyeSlash } from 'react-icons/fa'; 
import { TextField, Button, Dialog, DialogContent, DialogActions } from '@mui/material'; 

const SuperAdminLoginForm = ({ onLogin }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showForgotPasswordPopup, setShowForgotPasswordPopup] = useState(false);
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState(''); 
  const navigate = useNavigate(); 

  const handleLoginClick = async () => {
    setIsLoading(true);

    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/organizationRoutes/Organizationlogin`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams({
          email,
          password,
        }),
      });

      if (response.ok) {
        const data = await response.json();
        localStorage.setItem('token', data.result.id);
        localStorage.setItem('organization_id', data.result.id);
        localStorage.setItem('organization', data.result.organization);
        localStorage.setItem('companyName', data.result.organization);
        localStorage.setItem('isLoggedIn', 'true');
        localStorage.setItem('is_updated', data.result.is_updated);
        localStorage.setItem('manager_id', data.result.manager_id);
        localStorage.setItem('firstName', data.result.first_name);
        localStorage.setItem('lastName', data.result.last_name);

        toast.success('You have logged in successfully');
        onLogin();

        if (data.result.is_updated) {
          navigate('/super-admin');
        } else {
          navigate('/company-name');
        }
      } else {
        toast.error('Invalid credentials');
      }
    } catch (error) {
      console.error('Error during login:', error);
      toast.error('An error occurred. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleForgotPasswordClick = () => {
    setShowForgotPasswordPopup(true); 
  };

  const handleCloseForgotPasswordPopup = () => {
    setShowForgotPasswordPopup(false); 
  };

  const handleSubmitForgotPassword = async () => {
    if (!forgotPasswordEmail) {
      toast.error('Please enter a valid email address.');
      return;
    }
  
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(forgotPasswordEmail)) {
      toast.error('Please enter a valid email address.');
      return;
    }
  
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/organizationRoutes/organizationForgetpassword`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: forgotPasswordEmail }), 
      });
  
      const data = await response.json();
      if (response.ok) {
        toast.success('Verification mail sent to reset password.');
        handleCloseForgotPasswordPopup();
      } else {
        toast.error(data.message || 'Failed to send password reset email.');
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error('An error occurred while sending the reset password email.');
    }
  };
  
  const handleRegistrationClick = () => {
    navigate('/create-company'); 
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  return (
    <div>
      <ToastContainer />
      {isLoading && (
        <div className="modal-overlay">
          <div className="modal-content">
            <FidgetSpinner
              visible={true}
              height="80"
              width="80"
              ariaLabel="fidget-spinner-loading"
              backgroundColor="blue"
            />
          </div>
        </div>
      )}
      <div className="Super_Admin_Login_Container">
        <div className="Super_Admin_Login_Container-Left">
          <div className="Super_Admin_Login_Text">Login</div>
          <div className="Super_Admin_Login_input-form">
            <div className="Super_Admin_Login_input-fields">
              <div className="super-admin-input-container">
                <TextField
                  className="Super_Admin_Login_input-inputBox"
                  label="Email"
                  type="email"
                  variant="outlined"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  fullWidth
                />
              </div>
              <div className="super-admin-input-container">
                <TextField
                  className="Super_Admin_Login_input-inputBox"
                  label="Password"
                  type={showPassword ? 'text' : 'password'}
                  variant="outlined"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <span className="super-admin-eye-icon" onClick={togglePasswordVisibility}>
                        {showPassword ? <FaEyeSlash /> : <FaEye />}
                      </span>
                    ),
                  }}
                />
              </div>
            </div>
          </div>

          <div className="Super_Admin_Login_register-button">
            <div className="Super_Admin_Login_Button" onClick={handleLoginClick}>
              <div className="Super_Admin_Login_buttonText">Login</div>
            </div>
          </div>

          <div className="Super_Admin_Login_forget-password">
            <span 
              className="Super_Admin_Login_forget-password-text" 
              onClick={handleForgotPasswordClick} 
              style={{ cursor: 'pointer', color: 'blue' }}
            >
              Forgot <span>Password?</span>
            </span>
          </div>

          <div className="Super_Admin_registration">
            <p>Don't have an account? 
              <span className="signup-link" onClick={handleRegistrationClick} style={{ cursor: 'pointer', color: 'blue' }}>
                Sign up
              </span>
            </p>
          </div>
        </div>

        <div className="Super_Admin_Login_formRight">
          <img className="Super_Admin_Login_formimage" src="./Images/login-background.png" alt="Login" />
        </div>
      </div>

      
      <Dialog
        open={showForgotPasswordPopup}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
            handleCloseForgotPasswordPopup();
          }
        }}
        fullWidth={true}
        maxWidth="xs"
      >
        <DialogContent>
          <h3 className='reset-password-email-text'>Enter your Email to reset Password</h3>
          <TextField
            label="Enter your email"
            type="email"
            fullWidth
            value={forgotPasswordEmail}
            onChange={(e) => setForgotPasswordEmail(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseForgotPasswordPopup}>Cancel</Button>
          <Button onClick={handleSubmitForgotPassword} color="primary">Submit</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default SuperAdminLoginForm;
