import React, { useState, useRef, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FidgetSpinner } from "react-loader-spinner";
import styles from "./Employees-Flow.module.css";
import RecordRTC, { StereoAudioRecorder } from "recordrtc";
import FeedbackSuccess from "../Feedback_Success/Feedback_Success"

import { Feed } from "@mui/icons-material";
const EmployeeFeedback = () => { 
  const navigate = useNavigate();
  const location = useLocation();
  const [feedbackPoints, setFeedbackPoints] = useState([]);
  const [expandedKeyPoint, setExpandedKeyPoint] = useState({
    id: null,
    type: null,
  });
  const [textInputs, setTextInputs] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const [audioUrl, setAudioUrl] = useState({});
  const [recordingData, setRecordingData] = useState({});
  const [missingKeypoints, setMissingKeypoints] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const intervalRef = useRef({});
  const [isRecording, setIsRecording] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [isSuccessPopupVisible, setIsSuccessPopupVisible] = useState(false);
  const [isPlaying, setIsPlaying] = useState({});

  const [recordings, setRecordings] = useState({});

  const audioRefs = useRef({});
  const [playbackProgress, setPlaybackProgress] = useState({});
  const [recordingIndex, setRecordingIndex] = useState(null);
  const [pausedIndex, setPausedIndex] = useState(null);
  const [recordingProgress, setRecordingProgress] = useState({});
  const progressIntervals = useRef({});
  const recorderRef = useRef(null);
  const [uploaded, setUploaded] = useState({});
  const sliderRef = useRef(null);
  const [isFeedbackAdded, setIsFeedbackAdded] = useState({});
  const [isEmployeeFeeded, setIsEmployeeFeeded] = useState(false);
  const [activeButton, setActiveButton] = useState(null);
  const [isAnimating, setIsAnimating] = useState(false);
  const [feedbackAddedType, setFeedbackAddedType] = useState({}); 
  const [isStopping, setIsStopping] = useState(false);
  
  
  

  const updateSliderBackground = (slider) => {
    const value =
      ((slider.value - slider.min) / (slider.max - slider.min)) * 100;
    slider.style.background = `linear-gradient(to right, blue ${value}%, grey ${value}%)`;
  };
  useEffect(() => {
    const slider = sliderRef.current;

    if (slider) {
      const handleInput = () => updateSliderBackground(slider);
      slider.addEventListener("input", handleInput);

      updateSliderBackground(slider);

      return () => {
        slider.removeEventListener("input", handleInput);
      };
    }
  }, []);

  const togglePlay = (id) => {
    Object.keys(audioRefs.current).forEach((key) => {
      if (
        key !== id.toString() &&
        audioRefs.current[key] &&
        !audioRefs.current[key].paused
      ) {
        audioRefs.current[key].pause();
        setIsPlaying((prev) => ({ ...prev, [key]: false }));
        if (progressIntervals.current[key]) {
          clearInterval(progressIntervals.current[key]);
        }
      }
    });

    if (audioRefs.current[id]) {
      if (audioRefs.current[id].paused) {
        audioRefs.current[id].play();
        setIsPlaying((prev) => ({ ...prev, [id]: true }));

        progressIntervals.current[id] = setInterval(() => {
          const progress =
            (audioRefs.current[id].currentTime /
              audioRefs.current[id].duration) *
            100;

          setPlaybackProgress((prev) => ({
            ...prev,
            [id]: progress,
          }));

          if (
            audioRefs.current[id].currentTime === audioRefs.current[id].duration
          ) {
            clearInterval(progressIntervals.current[id]);
            setPlaybackProgress((prev) => ({ ...prev, [id]: 100 }));
            setIsPlaying((prev) => ({ ...prev, [id]: false }));
          }
        }, 100);
      } else {
        audioRefs.current[id].pause();
        setIsPlaying((prev) => ({ ...prev, [id]: false }));
        if (progressIntervals.current[id]) {
          clearInterval(progressIntervals.current[id]);
        }
      }
    }
  };

  const resumeRecording = () => {
    setIsAnimating(true);
    setPausedIndex(null);
    recorderRef.current.resumeRecording();
    progressIntervals.current[recordingIndex] = setInterval(() => {
      setRecordingProgress((prev) => ({
        ...prev,
        [recordingIndex]: prev[recordingIndex] + 1,
      }));
    }, 1000);
  };

  const startRecording = (id) => {
    if (isEmployeeFeeded) {
      playSound();
      handleAlreadyFeededNotification();
      return;
    }
  
    if (uploaded[id]) {
      playSound();
      toast.info("Audio already uploaded, you cannot record again.", {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        style: {
          background: "rgb(3, 177, 252)",
          color: "#ffffff",
          fontSize: "16px",
          borderRadius: "25px",
          borderBottomRightRadius: "0",
          boxShadow: "0 8px 20px rgba(0, 0, 0, 0.15)",
        },
      });
      return;
    }
  
    // Clear any previous recording sessions
    if (recorderRef.current) {
      recorderRef.current.stopRecording(() => {});
      recorderRef.current = null;
    }
  
    setIsAnimating(true);
  
    const maxRecordingDuration = 3 * 60 * 1000;
    let elapsedTime = 0;
  
    setRecordingIndex(id);
    setPausedIndex(null);
  
    navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
      recorderRef.current = new RecordRTC(stream, {
        type: "audio",
        mimeType: "audio/mp3",
        recorderType: StereoAudioRecorder,
        desiredSampRate: 44100,
      });
  
      recorderRef.current.startRecording();
  
      setRecordingProgress((prev) => ({ ...prev, [id]: 0 }));
  
      if (progressIntervals.current[id]) {
        clearInterval(progressIntervals.current[id]);
      }
  
      progressIntervals.current[id] = setInterval(() => {
        elapsedTime += 100;
        const progress = (elapsedTime / maxRecordingDuration) * 100;
  
        setRecordingProgress((prev) => ({
          ...prev,
          [id]: Math.min(progress, 100),
        }));
  
        if (elapsedTime >= maxRecordingDuration) {
          clearInterval(progressIntervals.current[id]);
          stopRecording(id);
        }
      }, 100);
    }).catch((error) => {
      playSound();
      toast.error("Error accessing microphone: " + error.message);
      console.error("Microphone error: ", error);
    });
  };
  

  const pauseRecording = () => {
    setIsAnimating(false);
    setPausedIndex(recordingIndex);
  
    if (recorderRef.current && recorderRef.current.pauseRecording) {
      recorderRef.current.pauseRecording();
    }
  
    if (progressIntervals.current[recordingIndex]) {
      clearInterval(progressIntervals.current[recordingIndex]);
    }
  };

  const stopRecording = (id) => {
    if (isStopping || !recorderRef.current) {
      return; // If already stopping or no recording is in progress, do nothing
    }
  
    setIsStopping(true); // Prevent further clicks while stopping
  
    setIsAnimating(false);
  
    recorderRef.current.stopRecording(() => {
      const blob = recorderRef.current.getBlob();
      const audioUrl = URL.createObjectURL(blob);
  
      setRecordings((prev) => ({
        ...prev,
        [id]: { audioUrl, blob },
      }));
  
      recorderRef.current = null; // Set the recorder to null once the recording is done
  
      setRecordingProgress((prev) => ({
        ...prev,
        [id]: 100,
      }));
  
      if (progressIntervals.current[id]) {
        clearInterval(progressIntervals.current[id]);
        delete progressIntervals.current[id];
      }
  
      setIsRecording(false);
      setPausedIndex(null);
      setIsStopping(false); // Allow stopping again in future recordings
    });
  };
  
  
 
  useEffect(() => {
    return () => {
      if (recorderRef.current) {
        recorderRef.current.stopRecording(() => {});
      }
      Object.keys(progressIntervals.current).forEach((key) => {
        clearInterval(progressIntervals.current[key]);
      });
    };
  }, []);

  const stopPreviousRecording = () => {
    if (
      expandedKeyPoint.id &&
      recordingData[expandedKeyPoint.id]?.mediaRecorder
    ) {
      recordingData[expandedKeyPoint.id].mediaRecorder.stop();
      clearInterval(intervalRef.current[expandedKeyPoint.id]);
      setIsRecording(false);
      setIsPaused(false);
      setAudioUrl((prevAudioUrls) => ({
        ...prevAudioUrls,
        [expandedKeyPoint.id]: recordingData[expandedKeyPoint.id].audioUrl,
      }));
    }
  };

  const handleVoiceButtonClick = (id) => {
    
    if (feedbackAddedType[id] === 'text') {
      playSound()
      toast.info('Text feedback already added. Cannot add voice feedback.',{
        position: "top-right",
            autoClose: 4000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            style: {
              borderRadius: "10px",
              borderBottomRightRadius: "0",
              background: "linear-gradient(to right, #f8d7da, #f5c6cb)", // Soft red gradient
              color: "#721c24", // Dark red text color
              padding: "10px",
              fontSize: "14px",
              lineHeight: "1.5",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
            }
        
      });
      return; 
    }
  
    setActiveButton({ id, type: "voice" });
  
    if (recordingIndex !== null) {
      stopRecording(recordingIndex);
    }
  
    if (expandedKeyPoint.id === id && expandedKeyPoint.type === "voice") {
      setExpandedKeyPoint({ id: null, type: null });
    } else {
     
      Object.keys(audioRefs.current).forEach((audioId) => {
        if (audioRefs.current[audioId]) {
          audioRefs.current[audioId].pause();
          audioRefs.current[audioId].currentTime = 0;
          setIsPlaying((prev) => ({ ...prev, [audioId]: false }));
          clearInterval(progressIntervals.current[audioId]);
        }
      });
  
      setExpandedKeyPoint({ id, type: "voice" });
    }
  };
  

  const handleTextButtonClick = (id) => {
    if (feedbackAddedType[id] === 'audio') {
      playSound()
      toast.info('Audio feedback already added. Cannot add text feedback.',{
        
      });
      return; 
    }
  
    setActiveButton({ id, type: "text" });
  
    setExpandedKeyPoint((prev) => {
      if (prev.id === id && prev.type === "text") {
        return { id: null, type: null };
      } else {
        stopPreviousRecording();
        return { id, type: "text" };
      }
    });
  };
  
  
  const handleTextChange = (id, value) => {
    setTextInputs((prev) => ({ ...prev, [id]: value }));
  };

  const handleDeleteText = (id) => {
    setTextInputs((prev) => {
      const newTextInputs = { ...prev };
      delete newTextInputs[id];
      return newTextInputs;
    });
  };

  const uploadToS3 = async (id) => {
    if (isEmployeeFeeded) {
      handleAlreadyFeededNotification();
      return;
    }
    if (uploaded[id]) {
      playSound();
      toast.info("Audio has already been uploaded.", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        style: {
          borderRadius: "10px",
          borderBottomRightRadius: "0",
          background: "linear-gradient(to right, #d1ecf1, #bee5eb)", // Light blue gradient
          color: "#0c5460", // Dark blue text color
          padding: "10px",
          fontSize: "14px",
          lineHeight: "1.5",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
        },
        icon: "ℹ️", // Info icon
      });
      ;
      ;
      return;
    }
  
    const { blob } = recordings[id]; 
    if (!blob) {
      playSound()
      toast.error("No audio recording found.");
      return;
    }
  
    const formData = new FormData();
    formData.append("file", blob, `feedback-${id}.wav`); 
    formData.append("id", id); 
  
    setIsLoading(true);
  
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/upload-audio`, {
        method: "POST",
        body: formData, 
        headers: {
          
        },
      });
  
      if (!response.ok) {
        const errorText = await response.text();
        playSound()
        toast.error(`Upload failed: ${response.status} - ${errorText}`, {
          position: "top-right",
          autoClose: 4000,
        });
        setIsLoading(false);
        return;
      }
  
      const data = await response.json();
      playSound()
      toast.success("Audio uploaded successfully.", {
        position: "bottom-right",
        autoClose: 3000,
      });
  
      setUploaded((prev) => ({ ...prev, [id]: true }));
      setIsFeedbackAdded((prev) => ({ ...prev, [id]: true }));
      setFeedbackAddedType((prev) => ({ ...prev, [id]: 'audio' }));
  
      setIsLoading(false);
    } catch (error) {
      playSound()
      toast.error(`Error uploading audio: ${error.message}`, {
        position: "top-right",
        autoClose: 4000,
      });
      setIsLoading(false);
    }
  };
  
  

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const feedbackId = searchParams.get("feedback_id");

    const fetchFeedbackData = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/feedback/getFeedBackByControl?id=${feedbackId}`
        );
        const data = await response.json();

        if (data.statusCode === 200 && data.result) {
          setFeedbackPoints(data.result.categories);

          const isEmployeeFeeded = data.result.is_employee_feeded;
          setIsEmployeeFeeded(isEmployeeFeeded);

          console.log("Is employee feeded:", isEmployeeFeeded);

          if (isEmployeeFeeded) {
            setIsSuccessPopupVisible(true);
          }

          const organizationIdFromResponse = data.result.organization_id;
          const storedOrganizationId = localStorage.getItem("organization_id");

          if (
            organizationIdFromResponse &&
            organizationIdFromResponse !== storedOrganizationId
          ) {
            localStorage.setItem("organization_id", organizationIdFromResponse);
            console.log(
              "Updated organization_id in localStorage:",
              organizationIdFromResponse
            );
          }
        } else {
          toast.error("Failed to fetch feedback data");
          console.error("Failed to fetch feedback data:", data.message);
        }
      } catch (error) {
        toast.error("Error fetching feedback data");
        console.error("Error fetching feedback data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchFeedbackData();
  }, [location.search]);

  const handleAlreadyFeededNotification = () => {
    toast.info("Your feedback is already submitted", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      style: {
        background: "#ffcc00",
        color: "#333",
        fontSize: "16px",
        borderRadius: "10px",
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
      },
      icon: "⚠️",
    });
  };

  const playSound = () => {
    const audio = new Audio("/sounds/tone.mp3");
    audio.play();
  };

  const handleSubmit = async () => {
    if (isEmployeeFeeded) {
      playSound();
      
      
      return;
    }

    const missing = feedbackPoints.filter((point) => {
      const hasText =
        textInputs[point.id] && textInputs[point.id].trim() !== "";
      const hasAudio = recordings[point.id] && recordings[point.id].audioUrl;
      const hasAddedFeedback = isFeedbackAdded[point.id];

      return !(hasText || hasAddedFeedback);
    });

    setMissingKeypoints(missing);
    setSubmitted(true);

    if (missing.length === 0) {
      setIsLoading(true);
      try {
        const feedbackId = new URLSearchParams(location.search).get(
          "feedback_id"
        );
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/feedback/updateFeedBackByEmployee?id=${feedbackId}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({}),
          }
        );

        const result = await response.json();

        if (response.ok && result.statusCode === 200) {
          setIsSuccessPopupVisible(true);
          
          playSound();
          // toast.success("Feedback submitted successfully", {
          //   position: "bottom-right",
          //   autoClose: 4000,
          //   hideProgressBar: true,
          //   closeOnClick: true,
          //   pauseOnHover: false,
          //   draggable: false,
          //   style: {
          //     background: "rgb(3, 177, 252)",
          //     color: "#ffffff",
          //     fontSize: "16px",
          //     borderRadius: "25px",
          //     borderBottomRightRadius: "0",
          //     boxShadow: "0 8px 20px rgba(0, 0, 0, 0.15)",
          //   },
          //   icon: "✔️",
          // });
        } else {
          const errorText = await response.text();
          playSound();
          toast.error("Failed to submit feedback.", {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            style: {
              borderRadius: "10px",
              borderBottomRightRadius: "0",
              background: "linear-gradient(to right, #f8d7da, #f5c6cb)", // Soft red gradient
              color: "#721c24", // Dark red text color
              padding: "10px",
              fontSize: "14px",
              lineHeight: "1.5",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
            },
            icon: "⚠️", // Warning icon
          });
          ;
        }
      } catch (error) {
        playSound();
        toast.error(`Error submitting feedback: ${error.message}`, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          style: {
            backgroundColor: "#f8d7da",
            color: "#721c24",
            fontSize: "16px",
            borderRadius: "25px",
            borderBottomRightRadius: "0",
            boxShadow: "0 8px 20px rgba(0, 0, 0, 0.15)",
          },
          icon: "❌",
        });
      } finally {
        setIsLoading(false);
      }
    } else {
      playSound();
      toast.warn("Please provide feedback for all categories.", {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        style: {
          borderRadius: "15px",
          borderBottomRightRadius: "0",
          background: "linear-gradient(to right, #fff3cd, #ffeeba)", // Yellow gradient
          color: "#856404", // Dark yellow text color
          padding: "12px",
          fontSize: "14px",
          lineHeight: "1.5",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
        },
        icon: "⚠️", // Exclamation icon for warnings
      });
      ;
    }
  };

  const handleDeleteRecording = (id) => {
    if (isFeedbackAdded[id]) {
      return;
    }

    console.log(`Deleting recording for id: ${id}`);
    if (recordings[id]) {
      console.log(`Audio URL for id ${id}:`, recordings[id].audioUrl);
      console.log(`Blob for id ${id}:`, recordings[id].blob);
    }

    setRecordings((prev) => {
      const updatedRecordings = { ...prev };
      delete updatedRecordings[id];
      return updatedRecordings;
    });

    setPlaybackProgress((prev) => {
      const updatedProgress = { ...prev };
      delete updatedProgress[id];
      return updatedProgress;
    });

    setIsPlaying((prev) => {
      const updatedPlaying = { ...prev };
      delete updatedPlaying[id];
      return updatedPlaying;
    });

    if (audioRefs.current[id]) {
      audioRefs.current[id].pause();
      audioRefs.current[id] = null;
    }

    if (progressIntervals.current[id]) {
      clearInterval(progressIntervals.current[id]);
      delete progressIntervals.current[id];
    }

    setRecordingProgress((prev) => {
      const updatedProgress = { ...prev };
      delete updatedProgress[id];
      return updatedProgress;
    });

    if (recordingIndex === id) {
      setRecordingIndex(null);
      setPausedIndex(null);
    }

    setExpandedKeyPoint({ id: null, type: null });
  };

  return (
    <div>
      <ToastContainer />
      {isLoading && (
        <div className="modal-overlay">
          <div className="modal-content">
            <FidgetSpinner
              visible={true}
              height="80"
              width="80"
              ariaLabel="fidget-spinner-loading"
              backgroundColor="blue"
            />
          </div>
        </div>
      )}
      {(isEmployeeFeeded  || isSuccessPopupVisible) && (
        <FeedbackSuccess/>
        
      )}

      {!isEmployeeFeeded && (
        <main>
          <div className={styles.efRecordInstructions}>
            <h1 className={styles.efRecordGreeting}>Hello!</h1>
            <h2 className={styles.efRecordInstructionTitle}>Instructions</h2>
            <p className={styles.efRecordInstructionText}>
              To Provide Voice Feedback, Click On The 'Record' Button To Start
              Recording. You Can Pause Or Stop The Recording At Any Time. Once
              Recorded, Use The Playback Controls To Listen To Your Feedback.
            </p>
            <p className={styles.efRecordInstructionText}>
              Adjust the Volume as needed. Click 'Add Feedback' Button for each
              categories and finally click Submit Button.
            </p>
          </div>
          <div className={styles.efMainContainer}>
            <div className={styles.efLeftContainer}>
              <img
                className={styles.efRobotImg}
                src="../Images/Robot.png"
                alt="robot-image"
              />
              <p className={styles.eflisteningparagraph}>
                <span
                  className={`${styles.efword} ${!isAnimating && styles.stop}`}
                >
                  Go
                </span>
                <span
                  className={`${styles.efword} ${!isAnimating && styles.stop}`}
                >
                  Ahead,
                </span>
                <span
                  className={`${styles.efword} ${!isAnimating && styles.stop}`}
                >
                  I'm
                </span>
                <span
                  className={`${styles.efword} ${!isAnimating && styles.stop}`}
                >
                  Listening...
                </span>
              </p>
              <img
                className={styles.efWaveImg}
                src="../Images/Wave.png"
                alt="wave-img"
              />
              <img
                className={`${styles.efMicrophone} ${
                  isAnimating ? styles.recordingActive : ""
                }`}
                src="../Images/mic.png"
                alt="microphone"
              />

              {/* <div className={styles.efPauseStopContainer}>
              <img
                className={styles.efPauseImg}
                src={isPaused ? '../Images/resume.png' : '../Images/pause.png'}
                alt='pause-img'
                
              />
              <img className={styles.efStopImg} src='../Images/stop.png' alt='stop-img'  />
            </div> */}
              {/* <button className={styles.efButton}>Add feedback</button> */}
            </div>
            <div className={styles.efRightContainer}>
              <div className={styles.efKeypointsParagraph}>
                Feedback - Key Points
              </div>
              {feedbackPoints.map((point) => (
                <React.Fragment key={point.id}>
                  {(expandedKeyPoint.id === point.id &&
                    expandedKeyPoint.type === "text") ||
                  (submitted && textInputs[point.id]) ? (
                    <div className={styles.efKeypointContainer1}>
                      <div className={styles.efKeypointContainer2}>
                        <div className={styles.efKeypointLeft}>
                          <img
                            src="../Images/feedbacks.png"
                            alt="star"
                            className={styles.efStarImg}
                          />
                          <div className={styles.efPointValue}>
                            {point.category}
                          </div>
                        </div>

                        <div className={styles.efKeypointRight}>
                          <div
                            className={`${styles.efvoicerecordicon} ${
                              activeButton?.id === point.id &&
                              activeButton?.type === "voice"
                                ? "active"
                                : ""
                            }`}
                            onClick={() => handleVoiceButtonClick(point.id)}
                          >
                            <img
                              className={styles.efVoiceImg}
                              src="../Images/Voice Button.png"
                              alt="voice-img"
                            />
                            <p className={styles.feedbackvoice}>Voice</p>
                          </div>

                          <div
                            className={`${styles.efTextfeedbackicon}  ${
                              activeButton?.id === point.id &&
                              activeButton?.type === "text"
                                ? "active"
                                : ""
                            } `}
                            // ${isFeedbackAdded[point.id] ? styles.disabled : ""}
                            // style={{
                            //   cursor: isFeedbackAdded[point.id] ? "not-allowed" : "pointer",
                            //   opacity: isFeedbackAdded[point.id] ? 0.5 : 1,
                            // }}
                            onClick={() => handleTextButtonClick(point.id)}
                          >
                            <img
                              className={styles.efTextImg}
                              src="../Images/Text-Button.png"
                              alt="text-img"
                            />
                            <p className={styles.feedbacktext}>Text</p>
                          </div>
                        </div>
                      </div>

                      <div className={styles.efTextDel}>
                        <textarea
                          className={styles.efTextArea}
                          placeholder="Enter your text..."
                          value={textInputs[point.id] || ""}
                          onChange={(e) =>
                            handleTextChange(point.id, e.target.value)
                          }
                        />
                        <img
                          className={`${styles.eftextdele} ${isFeedbackAdded[point.id] ? styles.disabled : ''}`}
                          style={{
                            cursor: isFeedbackAdded[point.id] ? 'not-allowed' : 'pointer',
                            opacity: isFeedbackAdded[point.id] ? 0.5 : 1,
                          }}

                          src="../Images/delete_icon.png"
                          
                          alt="delete-img"
                          onClick={() => handleDeleteText(point.id)}
                        />
                      </div>
                      <img
  src='../Images/send.png'
  className={`${styles.efSend} ${isFeedbackAdded[point.id] ? styles.disabled : ''}`}
  style={{
    cursor: isFeedbackAdded[point.id] ? 'not-allowed' : 'pointer',
    opacity: isFeedbackAdded[point.id] ? 0.5 : 1,
  }}
  onClick={async () => {
    if (!isFeedbackAdded[point.id]) {
      try {
        const payload = {
          id: point.id, 
          feedback: textInputs[point.id] || "" // Use the text input for this category
        };

        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/feedback/updateEmployeeFeedBackByCategory`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(payload),
        });

        const result = await response.json();

        if (result.statusCode === 200 && result.status === 'SUCCESS') {
          playSound()
          toast.success('Feedback category updated successfully', {
            position: "bottom-right",
            autoClose: 3000,
            style:{}
          });

          setIsFeedbackAdded((prev) => ({ ...prev, [point.id]: true }));
          setFeedbackAddedType((prev) => ({ ...prev, [point.id]: 'text' })); // Disable audio feedback
        } else {
          playSound()
          toast.error('Failed to update feedback category');
        }
      } catch (error) {
        playSound()
        toast.error(`Error updating feedback: ${error.message}`);
      }
    }
  }}
  alt="send"
/>


                    </div>
                  ) : (expandedKeyPoint.id === point.id &&
                      expandedKeyPoint.type === "voice") ||
                    (submitted && audioUrl[point.id]) ? (
                    <div className={styles.efKeypointContainer1}>
                      <div className={styles.efKeypointContainer2}>
                        <div className={styles.efKeypointLeft}>
                          <img
                            src="../Images/feedbacks.png"
                            alt="star"
                            className={styles.efStarImg}
                          />
                          <div className={styles.efPointValue}>
                            {point.category}
                          </div>
                        </div>
                        <div className={styles.efKeypointRight}>
                          <div
                            className={`${styles.efvoicerecordicon} ${
                              activeButton?.id === point.id &&
                              activeButton?.type === "voice"
                                ? "active"
                                : ""
                            }`}
                            onClick={() => handleVoiceButtonClick(point.id)}
                          >
                            <img
                              className={styles.efVoiceImg}
                              src="../Images/Voice Button.png"
                              alt="voice-img"
                            />
                            <p className={styles.feedbackvoice}>Voice</p>
                          </div>

                          <div
                            className={`${styles.efTextfeedbackicon} ${
                              activeButton?.id === point.id &&
                              activeButton?.type === "text"
                                ? "active"
                                : ""
                            }`}
                            onClick={() => handleTextButtonClick(point.id)}
                            
                          >
                            <img
                              className={styles.efTextImg}
                              src="../Images/Text-Button.png"
                              alt="text-img"
                            />
                            <p className={styles.feedbacktext}>Text</p>
                          </div>
                        </div>
                      </div>
                      {recordings[point.id] ? (
                        <div className={styles.efrecplayContainer1}>
                          
                          <div className={styles.efsubadditional}>
                            <audio
                              ref={(el) => (audioRefs.current[point.id] = el)}
                              src={recordings[point.id]?.audioUrl}
                              onTimeUpdate={() => {
                                  if (audioRefs.current[point.id]) {
                                  setPlaybackProgress((prev) => ({
                                    ...prev,
                                    [point.id]:
                                      (audioRefs.current[point.id].currentTime /
                                        audioRefs.current[point.id].duration) *
                                      100,
                                  }));
                                }
                              }}
                              onEnded={() =>
                                setIsPlaying((prev) => ({
                                  ...prev,
                                  [point.id]: false,
                                }))
                              }
                            />
                            {isPlaying[point.id] ? (
                              <img
                                src="../Images/pause.png"
                                className={styles.efplaybackicon}
                                onClick={() => togglePlay(point.id)}
                              />
                            ) : (
                              <img
                                src="../Images/resume.png"
                                className={styles.efplaybackicon}
                                onClick={() => togglePlay(point.id)}
                              />
                            )}
                            <input
                              type="range"
                              min="0"
                              max="100"
                              value={playbackProgress[point.id] || 0}
                              className={styles.efrangeSlider}
                              style={{
                                background: `linear-gradient(to right, #0883D9 ${
                                  playbackProgress[point.id] || 0
                                }%, rgb(217,217,217) ${playbackProgress[point.id] || 0}%)`,
                                transition: "background 0.1s linear",
                              }}
                              onChange={(e) => {
                                const seekTime =
                                  (e.target.value / 100) *
                                  audioRefs.current[point.id].duration;
                                audioRefs.current[point.id].currentTime =
                                  seekTime;
                                setPlaybackProgress((prev) => ({
                                  ...prev,
                                  [point.id]: e.target.value,
                                }));
                              }}
                            />
                            <img
                              src="../Images/delete_icon.png"
                              className={styles.efaudiodel}
                              alt="delete audio"
                              onClick={() => handleDeleteRecording(point.id)}
                              style={{
                                cursor: isFeedbackAdded[point.id]
                                  ? "not-allowed"
                                  : "pointer",
                                opacity: isFeedbackAdded[point.id] ? 0.5 : 1,
                              }} 
                              disabled={isFeedbackAdded[point.id]}
                            />
                          </div>
                          <button
                            className={styles.efButton}
                            onClick={() =>
                              isEmployeeFeeded
                                ? handleAlreadyFeededNotification()
                                : uploadToS3(point.id)
                            }
                          >
                            Add feedback
                          </button>
                        </div>
                      ) : (
                        <div className={styles.efrecplayContainer}>
                          <div className={styles.efsubadditional1}>
                            {recordingIndex === point.id ? (
                              pausedIndex === point.id ? (
                                <img
                                  src="../Images/mic.png"
                                  className={styles.efrecImg}
                                  onClick={resumeRecording}
                                />
                              ) : (
                                <img
                                  src="../Images/pause.png"
                                  className={styles.efpause}
                                  onClick={pauseRecording}
                                />
                              )
                            ) : (
                              <img
                                src="../Images/mic.png"
                                className={styles.efrecImg}
                                onClick={() => startRecording(point.id)}
                              />
                            )}
                           <img
  src="../Images/stop.png"
  className={styles.efstopImg}
  onClick={() => stopRecording(point.id)}
  style={{
    cursor: isStopping ? "not-allowed" : "pointer", // Show a not-allowed cursor if already stopping
    opacity: isStopping ? 0.5 : 1, // Visually indicate the button is processing
  }}
/>

                            <input
                              type="range"
                              min="0"
                              max="100"
                              value={recordingProgress[point.id] || 0}
                              className={styles.efrangeSlider}
                              style={{
                                background: `linear-gradient(to right, #0883D9 ${
                                  recordingProgress[point.id] || 0
                                }%, rgb(217,217,217) ${recordingProgress[point.id] || 0}%)`,
                                transition: "background 0.1s linear",
                              }}
                              readOnly
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  ) : (
                    <div className={styles.efKeypointContainer}>
                      <div className={styles.efKeypointLeft}>
                        <img
                          src="../Images/feedbacks.png"
                          alt="star"
                          className={styles.efStarImg}
                        />
                        <div className={styles.efPointValue}>
                          {point.category}
                        </div>
                      </div>
                      <div className={styles.efKeypointRight}>
                        <div
                          className={`${styles.efvoicerecordicon} ${
                            activeButton?.id === point.id &&
                            activeButton?.type === "voice"
                              ? "active"
                              : ""
                          }`}
                          onClick={() => handleVoiceButtonClick(point.id)}
                        >
                          <img
                            className={styles.efVoiceImg}
                            src="../Images/Voice Button.png"
                            alt="voice-img"
                          />
                          <p className={styles.feedbackvoice}>Voice</p>
                        </div>

                        <div
                          className={`${styles.efTextfeedbackicon} ${
                            activeButton?.id === point.id &&
                            activeButton?.type === "text"
                              ? "active"
                              : ""
                          } `}

                          onClick={() => handleTextButtonClick(point.id)}
                          
                        >
                          <img
                            className={styles.efTextImg}
                            src="../Images/Text-Button.png"
                            alt="text-img"
                          />
                          <p className={styles.feedbacktext}>Text</p>
                        </div>
                      </div>
                    </div>
                  )}
                </React.Fragment>
              ))}
              {submitted && missingKeypoints.length > 0 && (
                <>
                  <h2 className={styles.efMissingKeypointsTitle}>
                    Missing Points:
                  </h2>
                  {missingKeypoints.map((point) => (
                    <div className={styles.efKeypointContainer3} key={point.id}>
                      <div className={styles.efKeypointLeft}>
                        {/* <div className={styles.efPointNo}>{point.id}</div> */}
                        <img
                          src="../Images/feedbacks.png"
                          alt="star"
                          className={styles.efStarImg}
                        />
                        <div className={styles.efPointValue}>
                          {point.category}
                        </div>
                      </div>
                      <div className={styles.efKeypointRight}>
                        <div
                          className={`${styles.efvoicerecordicon} ${
                            activeButton?.id === point.id &&
                            activeButton?.type === "voice"
                              ? "active"
                              : ""
                          }`}
                          onClick={() => handleVoiceButtonClick(point.id)}
                        >
                          <img
                            className={styles.efVoiceImg}
                            src="../Images/Voice Button.png"
                            alt="voice-img"
                          />
                          <p className={styles.feedbackvoice}>Voice</p>
                        </div>

                        <div
                          className={`${styles.efTextfeedbackicon} ${
                            activeButton?.id === point.id &&
                            activeButton?.type === "text"
                              ? "active"
                              : ""
                          }`}
                          onClick={() => handleTextButtonClick(point.id)}
                        >
                          <img
                            className={styles.efTextImg}
                            src="../Images/Text-Button.png"
                            alt="text-img"
                          />
                          <p className={styles.feedbacktext}>Text</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              )}
              <button className={styles.efSubmitButton} onClick={handleSubmit}>
                Submit
              </button>
            </div>
          </div>
        </main>
      )}
    </div>
  );
};

export default EmployeeFeedback;
