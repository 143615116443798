import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FidgetSpinner } from 'react-loader-spinner';
import { TextField, MenuItem } from '@mui/material'; 
import './Create_Employee.css';

const AddEmployee = () => {
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [dob, setDob] = useState('');
  const [gender, setGender] = useState('');
  const [reportTo, setReportTo] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [role, setRole] = useState('');
  const [roles, setRoles] = useState([]);
  const [managers, setManagers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  useEffect(() => {
    const fetchRoles = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/employeeroleRoutes/getAllEmployeesRoles`);
        const data = await response.json();
        if (response.ok && data.statusCode === 200) {
          setRoles(data.result);
        } else {
          console.error('Error fetching roles:', data.message);
        }
      } catch (error) {
        console.error('Error fetching roles:', error);
      } finally {
        setIsLoading(false);
      }
    };

    const fetchManagers = async () => {
      const organizationId = localStorage.getItem('organization_id');
      if (!organizationId) {
        console.error('No organization_id found in localStorage');
        return;
      }

      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/employeeRoutes/getAllManagers?flag=false&organization_id=${organizationId}`);
        const data = await response.json();
        if (response.ok && data.statusCode === 200) {
          setManagers(data.result);
        } else {
          console.error('Error fetching managers:', data.message);
        }
      } catch (error) {
        console.error('Error fetching managers:', error);
      }
    };

    fetchRoles();
    fetchManagers();
  }, []);

  const handleFormSubmit = async () => {
    if (!firstName || !lastName || !email || !dob || !mobileNumber || !role) {
      toast.error('Please fill all required fields');
      return;
    }

    const payload = {
      first_name: firstName,
      last_name: lastName,
      email,
      dob,
      gender,
      manager_id: reportTo,
      mobile_number: mobileNumber,
      role,
      organization_id: localStorage.getItem('organization_id'),
    };

    setIsLoading(true);

    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/employeeRoutes/createEmployee`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        toast.success('Employee added successfully');
        navigate('/admin');
      } else {
        const errorData = await response.json();
        toast.error(`Form submission failed: ${errorData.message}`);
      }
    } catch (error) {
      toast.error('Error submitting form');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <ToastContainer />
      {isLoading && (
        <div className="modal-overlay">
          <div className="modal-content">
            <FidgetSpinner visible={true}
             height="80"
              width="80"
               ariaLabel="fidget-spinner-loading" 
               backgroundColor="blue"
               />
          </div>
        </div>
      )}
      <div className="formContainer-for-Employee">
        <div className="formLeft-for-Employee">
          <div className="company-input-fields-for-Employee">
            <div className="formField-for-Employee">
              <TextField
                className="inputBox-for-Employee"
                label={<span>First Name<span className="required-asterisk">*</span></span>}
                variant="outlined"
                value={firstName}
                onChange={(e) => setFirstName(capitalizeFirstLetter(e.target.value))}
                fullWidth
              />
              <TextField
                className="inputBox-for-Employee"
                label={<span>Email<span className="required-asterisk">*</span></span>}
                type="email"
                variant="outlined"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                fullWidth
              />
              <TextField
                className="inputBox-for-Employee"
                label={<span>DOB<span className="required-asterisk">*</span></span>}
                type="date"
                value={dob}
                onChange={(e) => setDob(e.target.value)}
                InputLabelProps={{ shrink: true }} 
                fullWidth
              />
              <TextField
                className="inputBox-for-Employee-gender"
                label={<span>Gender<span className="required-asterisk">*</span></span>}
                select
                value={gender}
                onChange={(e) => setGender(e.target.value)}
                fullWidth
                InputProps={{
                  style: { textAlign: 'left' } 
                }}
              >
                <MenuItem value="male">Male</MenuItem>
                <MenuItem value="female">Female</MenuItem>
                <MenuItem value="other">Other</MenuItem>
              </TextField>
            </div>
            <div className="formField-for-Employee">
              <TextField
                className="inputBox-for-Employee"
                label={<span>Last Name<span className="required-asterisk">*</span></span>}
                variant="outlined"
                value={lastName}
                onChange={(e) => setLastName(capitalizeFirstLetter(e.target.value))}
                fullWidth
              />
              <TextField
                className="inputBox-for-Employee"
                label={<span>Mobile Number<span className="required-asterisk">*</span></span>}
                variant="outlined"
                value={mobileNumber}
                onChange={(e) => setMobileNumber(e.target.value)}
                inputProps={{ maxLength: 10 }}
                fullWidth
              />
              <TextField
                className="inputBox-for-Employee-role"
                label={<span>Role<span className="required-asterisk">*</span></span>}
                select
                value={role}
                onChange={(e) => setRole(e.target.value)}
                fullWidth
                InputProps={{
                  style: { textAlign: 'left' } 
                }}
              >
                {roles.map((role) => (
                  <MenuItem key={role.id} value={role.employee_role}>
                    {role.employee_role}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                className="inputBox-for-Employee-report"
                label={<span>Report to<span className="required-asterisk">*</span></span>}
                select
                value={reportTo}
                onChange={(e) => setReportTo(e.target.value)}
                fullWidth
                InputProps={{
                  style: { textAlign: 'left' } 
                }}
              >
                {managers.map((manager) => (
                  <MenuItem key={manager.id} value={manager.id}>
                    {manager.first_name} {manager.last_name} - {manager.role}
                  </MenuItem>
                ))}
              </TextField>
            </div>
          </div>
          <div className="register-button-for-Employee">
            <div className="continueButton-for-Employee" onClick={handleFormSubmit}>
              <div className="buttonText-for-Employee">Add Employee</div>
            </div>
          </div>
        </div>
        <div className="formRight-for-Employee">
          <img className="image-for-Employee" src="./Images/Add Employee.png" alt="placeholder" />
        </div>
      </div>
    </div>
  );
};

export default AddEmployee;
