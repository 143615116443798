import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TextField, Button, InputAdornment } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './SuperAdminResetPassword.css'; 
import { FaEye, FaEyeSlash } from 'react-icons/fa';

const SuperAdminResetPassword = () => {
  const [email, setEmail] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showNewPassword, setShowNewPassword] = useState(false); 
  const [showConfirmPassword, setShowConfirmPassword] = useState(false); 
  const navigate = useNavigate();

  const handleResetPassword = async () => {
    if (!email || !newPassword || !confirmPassword) {
      toast.error('Please fill in all fields.');
      return;
    }
    if (newPassword !== confirmPassword) {
      toast.error('Passwords do not match.');
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/organizationRoutes/organizationChangePassword`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email,
          password: newPassword,
          confirmPassword,
        }),
      });

      if (!response.ok) {
        const errorText = await response.text(); 
        throw new Error(errorText);
      }

      const data = await response.json();
      console.log("API Response:", data);

      toast.success('Password has been reset successfully.');

      if (data.role === 'Super Admin') {
        localStorage.setItem('resetUserRole', 'Super Admin');  
        console.log("Stored Reset Role:", localStorage.getItem('resetUserRole'));
        
        setTimeout(() => {
          navigate('/super-admin-login');  
        }, 3000); 

      } else {
        toast.error('Role mismatch. Failed to redirect.');
      }

    } catch (error) {
      console.error('Error:', error);
      toast.error('An error occurred while resetting the password.');
    }
  };

  const toggleNewPasswordVisibility = () => {
    setShowNewPassword((prevShowPassword) => !prevShowPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword((prevShowPassword) => !prevShowPassword);
  };

  return (
    <div className="reset-password-container">
      <ToastContainer />
      <div className="reset-password-left">
        <h3 className="reset-password-title">Reset Your Password</h3>
        <div className="reset-password-input-fields">
          <TextField
            className="reset-password-input"
            label={<span>Email <span className="required-asterisk">*</span></span>}
            type="email"
            fullWidth
            value={email}
            onChange={(e) => setEmail(e.target.value)} 
          />
          <TextField
            className="reset-password-input"
            label={<span>New Password <span className="required-asterisk">*</span></span>}
            type={showNewPassword ? 'text' : 'password'} 
            fullWidth
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <span className="login-eye-icon" onClick={toggleNewPasswordVisibility}>
                    {showNewPassword ? <FaEyeSlash /> : <FaEye />}
                  </span>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            className="reset-password-input"
            label={<span>Confirm Password <span className="required-asterisk">*</span></span>}
            type={showConfirmPassword ? 'text' : 'password'} 
            fullWidth
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <span className="login-eye-icon" onClick={toggleConfirmPasswordVisibility}>
                    {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                  </span>
                </InputAdornment>
              ),
            }}
          />
        </div>
        <Button
          className="reset-password-button"
          variant="contained"
          color="primary"
          onClick={handleResetPassword}
        >
          Reset Password
        </Button>
      </div>
    </div>
  );
};

export default SuperAdminResetPassword;
