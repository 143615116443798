import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import LogOut from '../Components/LogOut/LogOut';
import './Header.css';

const Header = ({ isLoggedIn, onLogout }) => {
  const [organizationName, setOrganizationName] = useState('');
  const location = useLocation();

  
  const hideHeaderRoutes = [
    '/create-company',
    '/company-name',
    '/create-password',
    '/super-admin-login',
    '/user-login',
    '/reset-password/masterData/forgetPasswordEmail',
    '/update-managers',
    '/update-employee'
  ];

  const noLogoutRoutes = [
    '/employee-feedback',
    '/employee-feedback/employee'
  ];

  
  const shouldHideHeader = hideHeaderRoutes.some(route => location.pathname.startsWith(route));

  const shouldHideLogout = noLogoutRoutes.includes(location.pathname);

  useEffect(() => {
    const fetchOrganizationName = async () => {
      const organizationId = localStorage.getItem('organization_id');
      if (!organizationId) {
        console.error('No organization ID found');
        return;
      }

      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/organizationRoutes/getOrganizationById?organization_id=${organizationId}`
        );
        if (response.ok) {
          const data = await response.json();
          console.log('Fetched Organization Name:', data.result.organization);
          setOrganizationName(data.result.organization);
        } else {
          console.error('Failed to fetch organization name');
        }
      } catch (error) {
        console.error('Error fetching organization name:', error);
      }
    };

    if (!shouldHideHeader) {
      fetchOrganizationName();
    }
  }, [location.pathname, shouldHideHeader]);

  if (shouldHideHeader) {
    return null;
  }

  return (
    <div className="header">
      {organizationName && <h1 className="companyTitle">{organizationName}</h1>}

      {isLoggedIn && !shouldHideLogout && <LogOut onLogout={onLogout} />}
    </div>
  );
};

export default Header;
