import React from 'react';
import { useNavigate } from 'react-router-dom';
import './LogOut.css';

const LogOut = ({ onLogout }) => {
  const navigate = useNavigate();
  const userRole = localStorage.getItem('userRole');

  const handleLogoutClick = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('userRole');
    localStorage.removeItem('organization_id');
    localStorage.removeItem('manager_id');
    localStorage.removeItem('id');

    onLogout();

    if (userRole === 'SuperAdmin') {
      navigate('/super-admin-login');
    } else {
      navigate('/user-login');
    }
  };

  return (
    <div className="logout-button-container">
      <div className="logout-button-background" onClick={handleLogoutClick}>
        <h1 className="logout-button-text">Log out</h1>
      </div>
    </div>
  );
};

export default LogOut;
