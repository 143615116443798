import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './Create_Password.css';
import { FidgetSpinner } from 'react-loader-spinner';
import { TextField, InputAdornment } from '@mui/material'; 
import { FaEye, FaEyeSlash } from 'react-icons/fa'; 

const CreatePassword = ({ onLogin }) => {
  const [createEmailId, setCreateEmailId] = useState('');
  const [createPassword, setCreatePassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false); 
  const [showConfirmPassword, setShowConfirmPassword] = useState(false); 
  const navigate = useNavigate();

  const handleCreatePasswordClick = async () => {
    if (createPassword !== confirmPassword) {
      toast.error("Passwords do not match");
      return;
    }
  
    setIsLoading(true);
  
    const payload = {
      email: createEmailId,
      password: createPassword,
      confirmPassword: confirmPassword,
    };
  
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/employeeRoutes/updateEmployeePassword`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });
  
      const data = await response.json(); 
  
      if (response.ok && data.message === 'Password updated successfully.') {
        toast.success(data.message);
        
        const role = data.role; 
        onLogin(role); 
        navigate('/user-login'); 
      } else {
        toast.error(data.message || 'Form submission failed.');
      }
    } catch (error) {
      console.error('Error during form submission:', error);
      toast.error('An error occurred. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };
  
  
  
  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword((prevShowConfirmPassword) => !prevShowConfirmPassword);
  };

  return (
    <div>
      <ToastContainer />
      {isLoading && (
        <div className="modal-overlay">
          <div className="modal-content">
            <FidgetSpinner
              visible={true}
              height="80"
              width="80"
              ariaLabel="fidget-spinner-loading"
              backgroundColor="blue"
            />
          </div>
        </div>
      )}
      <div className="Create_Password_Login_Container">
        <div className="Create_Password_Login_Container-Left">
          <div className="Create_Password_Login_Text">Create Your Password</div>
          <div className="Create_Password_Login_input-form">
            <div className="Create_Password_Login_input-fields">
              <div className="Create_Password_input-container">
                <TextField
                  className="Create_Password_Login_input-inputBox"
                  label="Email"
                  type="email"
                  variant="outlined"
                  value={createEmailId}
                  onChange={(e) => setCreateEmailId(e.target.value)}
                  fullWidth
                />
              </div>
              <div className="Create_Password_input-container">
                <TextField
                  className="Create_Password_Login_input-inputBox"
                  label="Password"
                  type={showPassword ? 'text' : 'password'} 
                  variant="outlined"
                  value={createPassword}
                  onChange={(e) => setCreatePassword(e.target.value)}
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <span className="eye-icon" onClick={togglePasswordVisibility}>
                          {showPassword ? <FaEyeSlash /> : <FaEye />}
                        </span>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <div className="Create_Password_input-container">
                <TextField
                  className="Create_Password_Login_input-inputBox"
                  label="Confirm Password"
                  type={showConfirmPassword ? 'text' : 'password'} 
                  variant="outlined"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <span className="eye-icon" onClick={toggleConfirmPasswordVisibility}>
                          {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                        </span>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            </div>
          </div>
          <div className="Create_Password_Login_register-button">
            <div className="Create_Password_Login_Button" onClick={handleCreatePasswordClick}>
              <div className="Create_Password_Login_buttonText">Create Password</div>
            </div>
          </div>
        </div>
        <div className="Create_Password_Login_formRight">
          <img className="Create_Password_Login_formimage" src="./Images/login-background.png" alt="Login" />
        </div>
      </div>
    </div>
  );
};

export default CreatePassword;
