import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './Update_Organization.css';
import { FidgetSpinner } from 'react-loader-spinner';
import { TextField, MenuItem } from '@mui/material';

const UpdateOrganization = () => {
  const [companyTypes, setCompanyTypes] = useState([]);
  const [selectedRange, setSelectedRange] = useState(null);
  const [formData, setFormData] = useState({
    companyName: '',
    country: '',
    companyType: '',
    city: ''
  });
  const [isFormValid, setIsFormValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate(); 

  useEffect(() => {
    const storedCompanyName = localStorage.getItem('companyName');
    if (storedCompanyName) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        companyName: storedCompanyName,
      }));
    }
  }, []);



  useEffect(() => {
    const fetchCompanyTypes = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/masterdata/getAllMasterDropdowns?type=company_type`);
        const data = await response.json();
        if (response.ok && data.statusCode === 200) {
          const companyNames = data.result
            .filter(item => item.type === 'company_type') 
            .map(item => item.name);
          setCompanyTypes(companyNames);
        } else {
          toast.error('Error fetching company types');
        }
      } catch (error) {
        toast.error('Error fetching company types');
      }
    };
  
    fetchCompanyTypes();
  }, []);
  
  useEffect(() => {
    const { companyName, country, companyType, city } = formData;
    setIsFormValid(companyName && country && companyType && city && selectedRange);
  }, [formData, selectedRange]);

  const capitalizeFirstLetter = (string) => string.replace(/\b\w/g, (char) => char.toUpperCase());

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: capitalizeFirstLetter(value)
    }));
  };

  const handleRangeClick = (range) => {
    setSelectedRange(range);
  };

  const handleRegisterClick = async () => {
    const organizationId = localStorage.getItem('organization_id');
    const organizationName = formData.companyName;

    if (!organizationId || !organizationName) {
      toast.error('Organization ID or name is missing in local storage.');
      return;
    }

    if (isFormValid) {
      setIsLoading(true);
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/organizationRoutes/updateOrganization`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            organization_id: organizationId,
            organization: organizationName,
            company_type: formData.companyType,
            country: formData.country,
            city: formData.city,
            staff_count: selectedRange,
            is_updated: true
          }),
        });

        if (response.ok) {
          const data = await response.json();
          localStorage.setItem('companyName', formData.companyName);
          localStorage.setItem('companyType', formData.companyType);
          localStorage.setItem('country', formData.country);
          localStorage.setItem('city', formData.city);
          localStorage.setItem('staffCount', selectedRange);
          localStorage.setItem('is_updated', 'true');
          toast.success('Organization updated successfully');
          navigate('/super-admin');  
        } else {
          const errorData = await response.json();
          toast.error(`Failed to update organization: ${errorData.message}`);
        }
      } catch (error) {
        toast.error('An error occurred while updating the organization.');
      } finally {
        setIsLoading(false);
      }
    } else {
      toast.error('Please fill in all fields.');
    }
  };

  return (
    <div className="formContainer1">
      <ToastContainer />
      {isLoading && (
        <div className="modal-overlay">
          <div className="modal-content">
            <FidgetSpinner visible={true}
             height="80"
              width="80" 
             ariaLabel="fidget-spinner-loading" 
             backgroundColor="blue"
             />
          </div>
        </div>
      )}
      <div className="formLeft1">
        <div className='update-organization-text' >Update Organization</div>
        <div className="company-input-fields1">
          <div className="formField1">
            <TextField
              className="inputBox1"
              label={<span>Company Name<span className="required-asterisk">*</span></span>}
              variant="outlined"
              name="companyName"
              value={formData.companyName}
              onChange={handleInputChange}
              fullWidth
            />
            <TextField
              className="inputBox1"
              label={<span>Country<span className="required-asterisk">*</span></span>}
              variant="outlined"
              name="country"
              value={formData.country}
              onChange={handleInputChange}
              fullWidth
            />
          </div>
          <div className="formField1">
            <TextField
              className="inputBox1-for-CompanyType"
              label={<span>Company Type<span className="required-asterisk">*</span></span>}
              variant="outlined"
              name="companyType"
              select
              value={formData.companyType}
              onChange={handleInputChange}
              fullWidth
            >
              <MenuItem value="" disabled>Select Company Type</MenuItem>
              {companyTypes.map((type, index) => (
                <MenuItem key={index} value={type}>
                  {type}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              className="inputBox1"
              label={<span>City<span className="required-asterisk">*</span></span>}
              variant="outlined"
              name="city"
              value={formData.city}
              onChange={handleInputChange}
              fullWidth
            />
          </div>
        </div>

        <div className="staff-selection">
          <p className="staffs">Company Staff Members</p>
          <div className="staff-range-buttons">
            {['20-50', '51-100', '101-200', '201-500', '501-1000', '1000+'].map((range, index) => (
              <div
                key={index}
                className={`staff-range-button ${selectedRange === range ? 'selected' : ''}`}
                onClick={() => handleRangeClick(range)}
              >
                <div className="staff-range-box">
                  <div className="staff-range-text">{range}</div>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="register-button1">
          <div
            className={`continueButton1 ${isFormValid ? 'enabled' : 'disabled'}`}
            onClick={handleRegisterClick}
          >
            <div className="buttonText1">Update Organization</div>
          </div>
        </div>
      </div>
      <div className="formRight1">
        <img className="image1" src="./Images/Update Company Name.png" alt="Company" />
      </div>
    </div>
  );
};

export default UpdateOrganization;
