// import React from 'react';
// import styles from './Feedback_Success.module.css';
// // import { Navigate } from 'react-router-dom';
// import { useNavigate } from 'react-router-dom';

// const FeedbackSuccess = ({ onClose }) => {
//   const navigate = useNavigate();
//   return (
//     <div className={styles.overlay}>
//       <div className={styles.popup}>
//         <img
//           src='../Images/Success.png'
//           alt='Success'
//           className={styles.successImage}
//         />
//         <div className={styles.messageContainer}>
          
//           <p className={styles.successMessage}>You’ve already provided your feedback. Thank you!</p>
//           <img
//             src='../Images/voice-recognition.png' 
//             alt='Success Icon'
//             className={styles.smallSuccessImage}
//           />
//         </div>
//         <button className={styles.doneButton} onClick={navigate("/user-login")}>
//           Done
//         </button>
//       </div>
//     </div>
//   );
// };

// export default FeedbackSuccess;


import React from 'react';
import styles from './Feedback_Success.module.css';


const FeedbackSuccess = ({ onClose }) => {
  

  return (
    <div className={styles.overlay}>
      <div className={styles.popup}>
        <img
          src='../Images/Success.png'
          alt='Success'
          className={styles.successImage}
        />
        <div className={styles.messageContainer}>
          <p className={styles.successMessage}>
            Your Feedback Has Been Submitted.Thank You!
          </p>
          <img
            src='../Images/voice-recognition.png'
            alt='Success Icon'
            className={styles.smallSuccessImage}
          />
        </div>
        <button
          className={styles.doneButton}
          onClick={() =>window.close()} 
        >
          Done
        </button>
      </div>
    </div>
  );
};

export default FeedbackSuccess;

