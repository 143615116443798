import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FidgetSpinner } from 'react-loader-spinner';
import { TextField, MenuItem } from '@mui/material'; 
import './Update_Employee.css';

const UpdateEmployee = () => {
  const { id } = useParams(); 
  const navigate = useNavigate(); 
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [dob, setDob] = useState('');
  const [gender, setGender] = useState('');
  const [reportTo, setReportTo] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [doj, setDoj] = useState('');
  const [role, setRole] = useState('');
  const [roles, setRoles] = useState([]);
  const [managers, setManagers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/employeeroleRoutes/getAllEmployeesRoles`);
        const data = await response.json();
        if (response.ok && data.statusCode === 200) {
          setRoles(data.result);
        } else {
          toast.error('Error fetching roles');
        }
      } catch (error) {
        toast.error('Error fetching roles');
      }
    };

    const fetchManagers = async () => {
      const organizationId = localStorage.getItem('organization_id');
      if (!organizationId) {
        return;
      }

      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/employeeRoutes/getAllManagers?flag=false&organization_id=${organizationId}`);
        const data = await response.json();
        if (response.ok && data.statusCode === 200) {
          setManagers(data.result);
        } else {
          toast.error('Error fetching managers');
        }
      } catch (error) {
        toast.error('Error fetching managers');
      }
    };

    const fetchEmployeeData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/employeeRoutes/getEmployeeById?id=${id}`);
        const data = await response.json();
        if (response.ok && data.statusCode === 200) {
          const employee = data.result;
          
          
          const formattedDOB = employee.dob ? new Date(employee.dob).toISOString().split('T')[0] : '';
          const formattedDOJ = employee.doj ? new Date(employee.doj).toISOString().split('T')[0] : '';
    
          setFirstName(employee.first_name);
          setLastName(employee.last_name);
          setEmail(employee.email);
          setDob(formattedDOB); 
          setGender(employee.gender);
          setMobileNumber(employee.mobile_number);
          setDoj(formattedDOJ); 
          setRole(employee.role);
          setReportTo(employee.manager_id);
        } else {
          toast.error('Error fetching employee details');
        }
      } catch (error) {
        toast.error('Error fetching employee details');
      }
    };
    

    fetchRoles();
    fetchManagers();
    fetchEmployeeData();
  }, [id]);

  const handleUpdate = async () => {
    const organizationId = localStorage.getItem('organization_id');
    const organization = localStorage.getItem('organization');

    const payload = {
      id,
      first_name: firstName,
      last_name: lastName,
      email,
      organization_id: organizationId,
      organization,
      manager_id: reportTo,
      gender,
      role,
      dob,
      doj,
      mobile_number: mobileNumber,
    };

    setIsLoading(true); 

    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/employeeRoutes/updateEmployee`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        toast.success('Employee updated successfully.');
        setTimeout(() => {
          navigate('/admin'); 
        }, 4000); 
      } else {
        const errorData = await response.json();
        toast.error(`Update failed: ${errorData.message}`);
      }
    } catch (error) {
      toast.error('Error updating employee.');
    } finally {
      setIsLoading(false); 
    }
  };

  return (
    <div>
      <ToastContainer />
      {isLoading && (
        <div className="modal-overlay">
          <div className="modal-content">
            <FidgetSpinner visible={true} height="80" width="80" ariaLabel="fidget-spinner-loading" backgroundColor="blue" />
          </div>
        </div>
      )}
      <div className="formContainer-for-update-employee">
        <div className="formLeft-for-update-employee">
          <div className="signUpText-for-update-employee">Update Employee</div>
          <hr className="dotted-line-for-update-employee" />
          <div className="company-input-fields-for-update-employee">
            <div className="formField-for-update-employee">
              <TextField
                className="inputBox-for-update-employee"
                label="First Name"
                variant="outlined"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                fullWidth
              />
              <TextField
                className="inputBox-for-update-employee"
                label="Email Id"
                type="email"
                variant="outlined"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                fullWidth
              />
              <TextField
                className="inputBox-for-update-employee"
                label="DOB"
                type="date"
                value={dob}
                onChange={(e) => setDob(e.target.value)}
                InputLabelProps={{ shrink: true }} 
                fullWidth
              />
              <TextField
                className="inputBox-for-update-employee-gender"
                label="Gender"
                select
                value={gender}
                onChange={(e) => setGender(e.target.value)}
                fullWidth
                InputProps={{
                  style: { textAlign: 'left' } 
                }}
              >
                <MenuItem value="male">Male</MenuItem>
                <MenuItem value="female">Female</MenuItem>
                <MenuItem value="other">Other</MenuItem>
              </TextField>
              <TextField
                className="inputBox-for-update-employee-report"
                label="Report to"
                select
                value={reportTo}
                onChange={(e) => setReportTo(e.target.value)}
                fullWidth
                InputProps={{
                  style: { textAlign: 'left' } 
                }}
              >
                {managers.map(manager => (
                  <MenuItem key={manager.id} value={manager.id}>
                    {`${manager.first_name} ${manager.last_name} - ${manager.role}`}
                  </MenuItem>
                ))}
              </TextField>
            </div>
            <div className="formField-for-update-employee">
              <TextField
                className="inputBox-for-update-employee"
                label="Last Name"
                variant="outlined"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                fullWidth
              />
              <TextField
                className="inputBox-for-update-employee"
                label="Mobile Number"
                variant="outlined"
                value={mobileNumber}
                onChange={(e) => setMobileNumber(e.target.value)}
                inputProps={{ maxLength: 10 }}
                fullWidth
              />
              <TextField
                className="inputBox-for-update-employee"
                label="DOJ"
                type="date"
                value={doj}
                onChange={(e) => setDoj(e.target.value)}
                InputLabelProps={{ shrink: true }}
                fullWidth
              />
              <TextField
                className="inputBox-for-update-employee-role"
                label="Role"
                select
                value={role}
                onChange={(e) => setRole(e.target.value)}
                fullWidth
                InputProps={{
                  style: { textAlign: 'left' } 
                }}
              >
                {roles.map(role => (
                  <MenuItem key={role.id} value={role.employee_role}>
                    {role.employee_role}
                  </MenuItem>
                ))}
              </TextField>
            </div>
          </div>
          
          <div className="register-button-for-update-employee">
            <div className="continueButton-for-update-employee" onClick={handleUpdate}>
              <div className="buttonText-for-update-employee">Update Employee</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateEmployee;
