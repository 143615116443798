import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './Super_Admin_Dashboard.css';
import AddAdminTable from '../Add_Admin_Table/Add_Admin_Table';
import { FidgetSpinner } from 'react-loader-spinner';

const SuperAdminDashboard = () => {
  const navigate = useNavigate();
  const [companyDetails, setCompanyDetails] = useState({
    companyName: 'N/A',
    companyType: 'N/A',
    country: 'N/A',
    city: 'N/A',
    staffCount: 'N/A',
  });
  const [superadminDetails, setSuperAdminDetails] = useState({
    firstName: 'N/A',
    lastName: 'N/A',
    empId: 'N/A',
    role: 'Super Admin',
    profilePic: localStorage.getItem('profilePic') || '/Images/Default.png', 
  });
  const [isLoading, setIsLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [isImageUploadModalVisible, setIsImageUploadModalVisible] = useState(false); 
  const [selectedFile, setSelectedFile] = useState(null); 

  useEffect(() => {
    const fetchCompanyDetails = async () => {
      const organizationId = localStorage.getItem('organization_id');

      if (!organizationId) {
        toast.error('No organization ID found');
        setIsLoading(false);
        return;
      }

      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/organizationRoutes/getOrganizationById?organization_id=${organizationId}`);
        const data = await response.json();

        if (data.statusCode === 200) {
          const organization = data.result;

          setCompanyDetails({
            companyName: organization.organization,
            companyType: organization.company_type,
            country: organization.country,
            city: organization.city,
            staffCount: organization.staff_count,
          });

          setSuperAdminDetails((prevDetails) => ({
            ...prevDetails,
            profilePic: organization.profile_pic || '/Images/Default.png',
            firstName: organization.first_name,
            lastName: organization.last_name,
            empId: organization.id,
            role: 'Super Admin',
          }));

          
          localStorage.setItem('profilePic', organization.profile_pic || '/Images/Default.png');
        } else {
          toast.error('Failed to fetch organization details');
        }
      } catch (error) {
        toast.error('Error fetching company details');
      } finally {
        setIsLoading(false);
      }
    };

    fetchCompanyDetails();
  }, []);

  const handleAddAdminClick = () => {
    navigate('/add-admin');
  };

  const handleProfilePicClick = () => {
    setIsImageUploadModalVisible(true); 
  };

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]); 
  };

  const handleImageUpload = async () => {
    if (!selectedFile) {
      toast.error("Please select a file first.");
      return;
    }
  
    const organizationId = localStorage.getItem('organization_id'); 
  
    const formData = new FormData();
    formData.append('file', selectedFile);
    formData.append('id', organizationId); 
  
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/organizationRoutes/uploadPhotoForOrganization`, {
        method: 'POST',
        body: formData,
      });
      const data = await response.json();
  
      if (data.statusCode === 200) {
        toast.success("Image uploaded successfully");
  
        
        const uploadedPicUrl = data.result.profile_pic;
        localStorage.setItem('profilePic', uploadedPicUrl);
  
        
        setTimeout(() => {
          window.location.reload(); 
        }, 1500); 
      } else {
        toast.error("Failed to upload image");
      }
    } catch (error) {
      toast.error("Error uploading image");
    }
  };
  

  return (
    <div>
      <ToastContainer />
      {isLoading && (
        <div className="modal-overlay">
          <div className="modal-content">
            <FidgetSpinner
              visible={true}
              height="80"
              width="80"
              ariaLabel="fidget-spinner-loading"
              backgroundColor="blue"
            />
          </div>
        </div>
      )}
      {!isLoading && (
        <div className="super-admin-parent">
          <div className="super-admin-detials">
            <div className="super-admin-profile">
              <img
                src={superadminDetails.profilePic} 
                className="super-admin-profile-pic"
                alt="profile_pic"
              />
              <img
                src="./Images/Upload.png"
                className="upload-superadmin-pic"
                alt="upload_pic"
                onClick={handleProfilePicClick}
              />
              <div className="super-admin-del-container">
                <div className="super-admin-det-lables">
                  <p>Full Name</p>
                  <p>EMP ID</p>
                  <p>Role</p>
                </div>
                <div className="super-admin-det-columns">
                  <p>:</p>
                  <p>:</p>
                  <p>:</p>
                </div>
                <div className="super-admin-det-values">
                  <p>{superadminDetails.firstName} {superadminDetails.lastName}</p>
                  <p>{superadminDetails.empId}</p>
                  <p>{superadminDetails.role}</p>
                </div>
              </div>
            </div>
            <div className="super-admin-company-details">
              <div className="super-admin-company-details-pairs">
                <div className="company-name-edit-container">
                  <p className="super-admin-company-details-lable">Company Name</p>
                </div>
                <p className="super-admin-company-details-value">
                  {companyDetails.companyName}
                </p>
                <p className="super-admin-company-details-lable">Company Type</p>
                <p className="super-admin-company-details-value">
                  {companyDetails.companyType}
                </p>
                <p className="super-admin-company-details-lable">Country</p>
                <p className="super-admin-company-details-value">
                  {companyDetails.country}
                </p>
                <p className="super-admin-company-details-lable">City</p>
                <p className="super-admin-company-details-value">
                  {companyDetails.city}
                </p>
                <p className="super-admin-company-details-lable">Staff</p>
                <p className="super-admin-company-details-value">
                  {companyDetails.staffCount}
                </p>
              </div>
            </div>
          </div>
          <div className="super-admin-additions">
            <div className="super-admin-additions-header">
              <p className="super-admin-additions-header-head">Admin List</p>
              <div className="super-admin-addition-buttons">
                <button className="super-admin-addition-buttons-add-admin" onClick={handleAddAdminClick}>
                  <p className="add-admin-para">Add Admin</p>
                  <img
                    className="super-admin-addition-buttons-add-admin-pic"
                    src="/Images/add.png" 
                    alt="Add Admin"
                  />
                </button>
                <div className="super-admin-addition-search">
                  <input
                    className="super-admin-addition-search-input"
                    type="text"
                    placeholder=""
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                  <div className="super-admin-addition-search-input-style-container">
                    <img
                      className="super-admin-addition-search-input-img"
                      src="/Images/search-icon.png" 
                      alt="Search"
                    />
                  </div>
                </div>
              </div>
            </div>
            <AddAdminTable searchTerm={searchTerm} />
          </div>
        </div>
      )}

      {isImageUploadModalVisible && (
        <div className="image-upload-modal-overlay">
          <div className="image-upload-modal-content">
            <h3>Upload Profile Picture</h3>
            <input type="file" onChange={handleFileChange} />
            <button className="Image-upload-button" onClick={handleImageUpload}>Upload</button>
            <button className="Image-cancel-button" onClick={() => setIsImageUploadModalVisible(false)}>Cancel</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default SuperAdminDashboard;
