import React from 'react';
import { Box, Modal, Typography, Button, IconButton } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';

const FeedbackSuccessTesting = ({ open = true, onClose }) => {
  const navigate = useNavigate();

  const handleDone = () => {
    // Navigate back to employee login page
    navigate('/employee-login');
    
    // If onClose prop is provided, call it too
    if (onClose) {
      onClose();
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleDone}
      aria-labelledby="feedback-success-modal"
      aria-describedby="feedback-success-message"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          borderRadius: '8px',
        }}
      >
        <IconButton
          aria-label="close"
          onClick={handleDone}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <CheckCircleOutlineIcon sx={{ color: 'success.main', fontSize: 60, mb: 2 }} />
        <Typography id="feedback-success-modal" variant="h6" component="h2" sx={{ mt: 2, textAlign: 'center' }}>
          Your Feedback Has Been Submitted. Thank You!
        </Typography>
        <Typography id="feedback-success-message" sx={{ mt: 2, textAlign: 'center', color: 'text.secondary' }}>
          We appreciate your valuable input.
        </Typography>
        <Button variant="contained" onClick={handleDone} sx={{ mt: 3 }}>
          Done
        </Button>
      </Box>
    </Modal>
  );
};

export default FeedbackSuccessTesting;