import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FidgetSpinner } from 'react-loader-spinner';
import './Add_Admin.css'; 
import { TextField, MenuItem } from '@mui/material'; 

const AddAdmin = () => {
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [dob, setDob] = useState('');  
  const [gender, setGender] = useState('');
  const [reportTo, setReportTo] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [doj, setDoj] = useState('');  
  const [role, setRole] = useState('');
  const [roles, setRoles] = useState([]);
  const [managers, setManagers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  
  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/employeeroleRoutes/getAllEmployeesRoles`);
        const data = await response.json();
        if (response.ok && data.statusCode === 200) {
          setRoles(data.result);
        } else {
          toast.error('Error fetching roles');
        }
      } catch (error) {
        toast.error('Error fetching roles');
      }
    };

    const fetchManagers = async () => {
      const organizationId = localStorage.getItem('organization_id');
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/employeeRoutes/getAllManagers?flag=true&organization_id=${organizationId}`);
        const data = await response.json();
        if (response.ok && data.statusCode === 200) {
          setManagers(data.result);
        } else {
          toast.error('Error fetching managers');
        }
      } catch (error) {
        toast.error('Error fetching managers');
      }
    };
    
    fetchRoles();
    fetchManagers();
  }, []);

  const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1);

  const handleRegister = async () => {
    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      toast.error('Invalid email');
      return;
    }

    if (mobileNumber.length !== 10) {
      toast.error('Mobile number must be 10 digits');
      return;
    }

    const organizationId = localStorage.getItem('organization_id');
    const organization = localStorage.getItem('organization');
    
   
    const payload = {
      first_name: firstName,
      last_name: lastName,
      email,
      organization_id: organizationId,
      organization,
      manager_id: reportTo,
      gender,
      role,
      dob: dob || '',  
      doj: doj || '',  
      mobile_number: mobileNumber,
    };

    setIsLoading(true);

    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/employeeRoutes/createEmployee`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        const result = await response.json();
        toast.success('Admin added successfully.');
        navigate('/super-admin');
      } else {
        const errorData = await response.json();
        toast.error(`Form submission failed: ${errorData.message}`);
      }
    } catch (error) {
      toast.error('Error submitting form.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <ToastContainer />
      {isLoading && (
        <div className="modal-overlay">
          <div className="modal-content">
            <FidgetSpinner visible={true} 
            height="80"
             width="80"
              ariaLabel="fidget-spinner-loading"
              backgroundColor="blue"
               />
          </div>
        </div>
      )}
      <div className="formContainer-for-admin">
        <div className="formLeft-for-admin">
          <div className="signUpText-for-admin">Admin Registration</div>
          <hr className="dotted-line-for-admin" />
          <div className="company-input-fields-for-admin">
            <div className="formField-for-admin">
              <TextField
                className="inputBox-for-admin"
                label={<span>First Name<span className="required-asterisk">*</span></span>}
                variant="outlined"
                value={firstName}
                onChange={(e) => setFirstName(capitalizeFirstLetter(e.target.value))}
                fullWidth
              />
              <TextField
                className="inputBox-for-admin"
                label={<span>Email<span className="required-asterisk">*</span></span>}
                type="email"
                variant="outlined"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                fullWidth
              />
              <TextField
                className="inputBox-for-admin"
                label={<span>DOB<span className="required-asterisk">*</span></span>}
                type="date"
                value={dob || ''} 
                onChange={(e) => setDob(e.target.value)}  
                InputLabelProps={{ shrink: true }} 
                fullWidth
              />
              <TextField
                className="inputBox-for-admin-gender"
                label={<span>Gender<span className="required-asterisk">*</span></span>}
                select
                value={gender}
                onChange={(e) => setGender(e.target.value)}
                fullWidth
                InputProps={{
                  style: { textAlign: 'left' } 
                }}
              >
             
                <MenuItem value="Male">Male</MenuItem>
                <MenuItem value="Female">Female</MenuItem>
                <MenuItem value="Other">Other</MenuItem>
              </TextField>
              <TextField
                className="inputBox-for-admin-report"
                label={<span>Report To<span className="required-asterisk">*</span></span>}
                select
                value={reportTo}
                onChange={(e) => setReportTo(e.target.value)}
                fullWidth
                InputProps={{
                  style: { textAlign: 'left' } 
                }}
              >
                {managers.map((manager) => (
                  <MenuItem key={manager.id} value={manager.id}>
                    {manager.first_name} {manager.last_name} - {manager.role}
                  </MenuItem>
                ))}
              </TextField>
            </div>
            <div className="formField-for-admin1">
              <TextField
                className="inputBox-for-admin"
                label={<span>Last Name<span className="required-asterisk">*</span></span>}
                variant="outlined"
                value={lastName}
                onChange={(e) => setLastName(capitalizeFirstLetter(e.target.value))}
                fullWidth
              />
              <TextField
                className="inputBox-for-admin"
                label={<span>Mobile Number<span className="required-asterisk">*</span></span>}
                variant="outlined"
                value={mobileNumber}
                onChange={(e) => setMobileNumber(e.target.value)}
                inputProps={{ maxLength: 10 }}
                fullWidth
              />
              <TextField
                className="inputBox-for-admin"
                label={<span>DOJ<span className="required-asterisk">*</span></span>}
                type="date"
                value={doj || ''}  
                onChange={(e) => setDoj(e.target.value)}  
                InputLabelProps={{ shrink: true }} 
                fullWidth
              />
              <TextField
                className="inputBox-for-admin-role"
                label={<span>Role<span className="required-asterisk">*</span></span>}
                select
                value={role}
                onChange={(e) => setRole(e.target.value)}
                fullWidth
                InputProps={{
                  style: { textAlign: 'left' } 
                }}
              >
                {roles.map((role) => (
                  <MenuItem key={role.id} value={role.employee_role}>
                    {role.employee_role}
                  </MenuItem>
                ))}
              </TextField>
            </div>
          </div>
          <div className="register-button-for-admin">
            <div className="continueButton-for-admin" onClick={handleRegister}>
              <div className="buttonText-for-admin">Add Admin</div>
            </div>
          </div>
        </div>
        <div className="formRight-for-admin">
          <img className="image-for-admin" src="./Images/Add Admin.png" alt="Add Admin" />
        </div>
      </div>
    </div>
  );
};

export default AddAdmin;
