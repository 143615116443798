// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.logout-button-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    cursor: pointer;
    margin-right: 35px;
  }
  
  .logout-button-background {
    width: 80px;
    height: 30px;
    display: flex;
    background: black;
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    color: white;
  }
  
  .logout-button-text {
    color: white;
    font-size: 12px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    word-wrap: break-word;
  }



  @media only screen and (max-width: 768px) {

  .logout-button-background {
    width: 50px;
    height: 20px;
  }
  .logout-button-text {
    font-size: 8px;
  }
  .logout-button-container {
   position: relative;
   left: 30px;
  }
}`, "",{"version":3,"sources":["webpack://./src/Components/LogOut/LogOut.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,eAAe;IACf,yBAAyB;IACzB,eAAe;IACf,kBAAkB;EACpB;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,aAAa;IACb,iBAAiB;IACjB,kBAAkB;IAClB,uBAAuB;IACvB,mBAAmB;IACnB,YAAY;EACd;;EAEA;IACE,YAAY;IACZ,eAAe;IACf,kCAAkC;IAClC,gBAAgB;IAChB,qBAAqB;EACvB;;;;EAIA;;EAEA;IACE,WAAW;IACX,YAAY;EACd;EACA;IACE,cAAc;EAChB;EACA;GACC,kBAAkB;GAClB,UAAU;EACX;AACF","sourcesContent":[".logout-button-container {\n    display: flex;\n    flex-wrap: wrap;\n    justify-content: flex-end;\n    cursor: pointer;\n    margin-right: 35px;\n  }\n  \n  .logout-button-background {\n    width: 80px;\n    height: 30px;\n    display: flex;\n    background: black;\n    border-radius: 5px;\n    justify-content: center;\n    align-items: center;\n    color: white;\n  }\n  \n  .logout-button-text {\n    color: white;\n    font-size: 12px;\n    font-family: 'Poppins', sans-serif;\n    font-weight: 500;\n    word-wrap: break-word;\n  }\n\n\n\n  @media only screen and (max-width: 768px) {\n\n  .logout-button-background {\n    width: 50px;\n    height: 20px;\n  }\n  .logout-button-text {\n    font-size: 8px;\n  }\n  .logout-button-container {\n   position: relative;\n   left: 30px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
