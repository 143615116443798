import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { FidgetSpinner } from 'react-loader-spinner';
import './Add_Admin_Table.css';

const AddAdminTable = ({ searchTerm }) => {
    const [data, setData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(8); 
    const [isLoading, setIsLoading] = useState(false); 
    const navigate = useNavigate(); 
   
    useEffect(() => {
        const fetchData = async () => {
            const manager_id = localStorage.getItem('manager_id');
            if (!manager_id) {
                console.error('No manager ID found in local storage.');
                return;
            }
            setIsLoading(true); 
            try {
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/employeeRoutes/employeesByManagerId?manager_id=${manager_id}`);
                const result = await response.json();

                if (response.ok && result.statusCode === 200) {
                    setData(result.result.map((item, index) => ({
                        id: item.id,
                        name: `${item.first_name} ${item.last_name}`,
                        email: item.email,
                        empId: item.id,
                        gender: item.gender,
                        role: item.role,
                        doj: new Date(item.doj).toLocaleDateString(),
                        phno: item.mobile_number,
                       imageUrl: item.gender.toLowerCase() === 'female' ? './Images/female employees.png' : './Images/male employees.png'
                    })));
                } else {
                    console.error('Failed to fetch data.');
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, []);

    const filteredData = data.filter((admin) =>
        admin.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        admin.role.toLowerCase().includes(searchTerm.toLowerCase()) ||
        admin.empId.toString().includes(searchTerm) ||
        admin.email.toLowerCase().includes(searchTerm.toLowerCase())
    );



    useEffect(() => {
       
        const handleResize = () => {
            if (window.innerWidth <= 768) {
                setItemsPerPage(2);
            } else {
                setItemsPerPage(8); 
            }
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

     const handleEdit = (employee) => {
        navigate(`/update-managers/${employee.id}`);
     };

    

    const handleDelete = async (id) => {
        const managerId = localStorage.getItem('manager_id');
        if (!managerId) {
            console.error('No manager ID found in local storage.');
            return;
        }

        setIsLoading(true); 
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/employeeRoutes/deleteEmployee?id=${id}`, {
                method: 'DELETE'
            });

            const result = await response.json();
            if (response.ok && result.statusCode === 200) {
                setData(data.filter(item => item.id !== id));
                console.log(result.message);
            } else {
                console.error('Failed to delete employee.');
            }
        } catch (error) {
            console.error('Error deleting data:', error);
        } finally {
            setIsLoading(false); 
        }
    };

    return (
        <div className='add-admin-table-parent'>
            {isLoading && (
                <div className="modal-overlay">
                    <div className="modal-content">
                        <FidgetSpinner
                            visible={true}
                            height="80"
                            width="80"
                            ariaLabel="fidget-spinner-loading"
                            backgroundColor="blue"
                        />
                    </div>
                </div>
            )}
            {!isLoading && (
                <>
                    <div className='add-admin-table-heading'>
                        <h5 className='admin-checkbox-header'>Select</h5>
                        <h5 className='admin-added-profile-name'>Name</h5>
                        <h5 className='admin-emp-id'>Employee Id</h5>
                        <h5 className='admin-gender'>Gender</h5>
                        <h5 className='admin-role'>Role</h5>
                        <h5 className='admin-doj'>DOJ</h5>
                        <h5 className='admin-phno'>Mobile Number</h5>
                        <h5>Manage</h5>
                    </div>
                    <div className='add-admin-table-details'>
                        {currentItems.map((admin) => (
                            <div className='admins-details' key={admin.id}>
                                <input type='checkbox' className='admin-checkbox' />
                                <div className='admin-added-image'>
                                    <img src={admin.imageUrl} className='admin-added-profile-pic' alt="Admin Profile" />
                                    <div className='added-admin-info'>
                                        <p className='added-admin-name'>{admin.name}</p>
                                        <p className='added-admin-email-id'>{admin.email}</p>
                                    </div>
                                </div>
                                <p className='added-admins-roles-and-details1'>{admin.empId}</p>
                                <p className='added-admins-roles-and-details2'>{admin.gender}</p>
                                <p className='added-admins-roles-and-details3'>{admin.role}</p>
                                <p className='added-admins-roles-and-details4'>{admin.doj}</p>
                                <p className='added-admins-roles-and-details5'>{admin.phno}</p>
                                <div className='manage-icons'>
                                    <img src='./Images/Edit.png' className='edit-icon' onClick={() => handleEdit(admin)} alt="Edit"  />
                                    <img src='./Images/delete_icon.png' className='delete-icon' onClick={() => handleDelete(admin.id)} alt="Delete" />
                                </div>
                            </div>
                        ))}
                    </div>
                    {filteredData.length > itemsPerPage && (
                        <Pagination
                            itemsPerPage={itemsPerPage}
                            totalItems={data.length}
                            paginate={paginate}
                            currentPage={currentPage}
                        />
                    )}
                </>
            )}
          
        </div>
    );
};

const Pagination = ({ itemsPerPage, totalItems, paginate, currentPage }) => {
    const pageNumbers = [];
    const totalPages = Math.ceil(totalItems / itemsPerPage);

    for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
    }

    const maxPageNumbersToShow = 5;
    let startPage = Math.max(1, currentPage - Math.floor(maxPageNumbersToShow / 2));
    let endPage = startPage + maxPageNumbersToShow - 1;

    if (endPage > pageNumbers.length) {
        endPage = pageNumbers.length;
        startPage = Math.max(1, endPage - maxPageNumbersToShow + 1);
    }

    return (
        <nav>
            <ul className="pagination">
                {startPage > 1 && (
                    <>
                        <li className="page-item">
                            <button onClick={() => paginate(1)} className="page-link">1</button>
                        </li>
                        {startPage > 2 && <li className="page-item disabled"><span className="page-link">...</span></li>}
                    </>
                )}
                {pageNumbers.slice(startPage - 1, endPage).map(number => (
                    <li key={number} className={`page-item ${currentPage === number ? 'active' : ''}`}>
                        <button onClick={() => paginate(number)} className="page-link">
                            {number}
                        </button>
                    </li>
                ))}
                {endPage < pageNumbers.length && (
                    <>
                        {endPage < pageNumbers.length - 1 && <li className="page-item disabled"><span className="page-link">...</span></li>}
                        <li className="page-item">
                            <button onClick={() => paginate(pageNumbers.length)} className="page-link">{pageNumbers.length}</button>
                        </li>
                    </>
                )}
            </ul>
        </nav>
    );
};

export default AddAdminTable;
