// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header {
    width: 100%;
    height: 50px;
    background: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding-left: 47px;
  }
  
  .companyTitle {
    color: #555555;
    font-size: 22px;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    word-wrap: break-word;
  }

  @media only screen and (max-width: 768px) {
    .companyTitle {
      font-size: 14px;
      position: relative;
      
    }
  }  `, "",{"version":3,"sources":["webpack://./src/Header/Header.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,iBAAiB;IACjB,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,sBAAsB;IACtB,kBAAkB;EACpB;;EAEA;IACE,cAAc;IACd,eAAe;IACf,kCAAkC;IAClC,gBAAgB;IAChB,qBAAqB;EACvB;;EAEA;IACE;MACE,eAAe;MACf,kBAAkB;;IAEpB;EACF","sourcesContent":[".header {\n    width: 100%;\n    height: 50px;\n    background: white;\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    box-sizing: border-box;\n    padding-left: 47px;\n  }\n  \n  .companyTitle {\n    color: #555555;\n    font-size: 22px;\n    font-family: 'Poppins', sans-serif;\n    font-weight: 600;\n    word-wrap: break-word;\n  }\n\n  @media only screen and (max-width: 768px) {\n    .companyTitle {\n      font-size: 14px;\n      position: relative;\n      \n    }\n  }  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
