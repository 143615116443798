import React, { useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './Organization_Signup.css';
import { FidgetSpinner } from 'react-loader-spinner';
import { FaEye, FaEyeSlash } from 'react-icons/fa'; 
import { TextField } from '@mui/material'; 

const OrganizationSignup = ({ onLogin }) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [companyEmail, setCompanyEmail] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [telephoneNumber, setTelephoneNumber] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isChecked, setIsChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const capitalizeFirstLetter = (text) => {
    return text.charAt(0).toUpperCase() + text.slice(1);
  };

  const handleRegisterClick = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailRegex.test(companyEmail)) {
      toast.error('Please provide a valid email');
      return;
    }

    if (mobileNumber.length !== 10) {
      toast.error('Phone number should be exactly 10 digits');
      return;
    }

    if (password !== confirmPassword) {
      toast.error('Passwords do not match');
      return;
    }

    if (isChecked) {
      setIsLoading(true);

      const formData = new URLSearchParams();
      formData.append('email', companyEmail);
      formData.append('first_name', firstName);
      formData.append('last_name', lastName);
      formData.append('organization', companyName);
      formData.append('password', password);
      formData.append('mobile_number', mobileNumber);
      formData.append('telephone_number', telephoneNumber);

      fetch(`${process.env.REACT_APP_BACKEND_URL}/organizationRoutes/createOrganization`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: formData.toString(),
      })
        .then((response) => {
          setIsLoading(false);
          if (response.ok) {
            return response.json();
          } else {
            return response.json().then((data) => {
              throw new Error(data.message || 'Something went wrong');
            });
          }
        })
        .then((data) => {
          console.log(data);
          localStorage.setItem('companyName', companyName);
          setCompanyName(companyName);
          toast.success('Verification email sent. Please check your email.');
          onLogin();
        })
        .catch((error) => {
          setIsLoading(false);
          console.error(error);
          if (error.message === 'The email already exists in db. Please contact us.') {
            toast.error('Email already exists');
          } else {
            toast.error('Failed to register company');
          }
        });
    } else {
      toast.error('You must agree to the terms and conditions');
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword((prevShowConfirmPassword) => !prevShowConfirmPassword);
  };

  return (
    <div>
      <ToastContainer position="top-right" autoClose={5000} />
      {isLoading && (
        <div className="modal-overlay">
          <div className="modal-content">
            <FidgetSpinner
              visible={true}
              height="80"
              width="80"
              ariaLabel="fidget-spinner-loading"
              backgroundColor="blue"
            />
          </div>
        </div>
      )}
      <div className="Organization_Signup_Container">
        <div className="Organization_Signup_Container-Left">
          <div className="Organization_Signup_Text">Sign-up for your company</div>
          <div className="Organization_Signup_input-form">
            <div className="Organization_Signup_input-fields">
              <div className="Organization_Signup_input-container">
                <TextField
                  className="Organization_Signup_input-inputBox"
                  label={<span>First Name<span className="required-asterisk">*</span></span>}
                  variant="outlined"
                  value={firstName}
                  onChange={(e) => setFirstName(capitalizeFirstLetter(e.target.value))}
                  fullWidth
                />
              </div>
              <div className="Organization_Signup_input-container">
                <TextField
                  className="Organization_Signup_input-inputBox"
                  label={<span>Last Name<span className="required-asterisk">*</span></span>}
                  variant="outlined"
                  value={lastName}
                  onChange={(e) => setLastName(capitalizeFirstLetter(e.target.value))}
                  fullWidth
                />
              </div>
              <div className="Organization_Signup_input-container">
                <TextField
                  className="Organization_Signup_input-inputBox"
                  label={<span>Company Name<span className="required-asterisk">*</span></span>}
                  variant="outlined"
                  value={companyName}
                  onChange={(e) => setCompanyName(capitalizeFirstLetter(e.target.value))}
                  fullWidth
                />
              </div>
              <div className="Organization_Signup_input-container">
                <TextField
                  className="Organization_Signup_input-inputBox"
                  label={<span>Company Email<span className="required-asterisk">*</span></span>}
                  type="email"
                  variant="outlined"
                  value={companyEmail}
                  onChange={(e) => setCompanyEmail(e.target.value)}
                  fullWidth
                />
              </div>
              <div className="Organization_Signup_input-container">
                <TextField
                  className="Organization_Signup_input-inputBox"
                  label={<span>Mobile Number<span className="required-asterisk">*</span></span>}
                  variant="outlined"
                  value={mobileNumber}
                  onChange={(e) => setMobileNumber(e.target.value)}
                  inputProps={{ maxLength: 10 }}
                  fullWidth
                />
              </div>
              <div className="Organization_Signup_input-container">
                <TextField
                  className="Organization_Signup_input-inputBox"
                  label="Telephone Number (Optional)"
                  variant="outlined"
                  value={telephoneNumber}
                  onChange={(e) => setTelephoneNumber(e.target.value)}
                  fullWidth
                />
              </div>

             
              <div className="Organization_Signup_input-container">
                <TextField
                  className="Organization_Signup_input-inputBox"
                  type={showPassword ? 'text' : 'password'}
                  label={<span>Password<span className="required-asterisk">*</span></span>}
                  variant="outlined"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <span className="password-toggle-icon" onClick={togglePasswordVisibility}>
                        {showPassword ? <FaEyeSlash /> : <FaEye />}
                      </span>
                    ),
                  }}
                />
              </div>

              
              <div className="Organization_Signup_input-container">
                <TextField
                  className="Organization_Signup_input-inputBox"
                  type={showConfirmPassword ? 'text' : 'password'}
                  label={<span>Confirm Password<span className="required-asterisk">*</span></span>}
                  variant="outlined"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <span className="password-toggle-icon" onClick={toggleConfirmPasswordVisibility}>
                        {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                      </span>
                    ),
                  }}
                />
              </div>
            </div>
          </div>

          <div className="terms">
            <input
              className="checkbox"
              type="checkbox"
              checked={isChecked}
              onChange={handleCheckboxChange}
            />
            <div className="termsText">
              By continuing, I am agreeing to the
              <span className="link"> Terms and Conditions</span> and
              <span className="link"> Privacy Policy</span>.
            </div>
          </div>

          <div className="register-button">
            <div className={`continueButton ${isChecked ? 'enabled' : 'disabled'}`} onClick={handleRegisterClick}>
              <div className="buttonText">Continue to Register Company</div>
            </div>
          </div>
        </div>

        <div className="Organization_Signup_formRight">
          <img className="Organization_Signup_formimage" src="./Images/create_company.png" alt="Company Signup" />
        </div>
      </div>
    </div>
  );
};

export default OrganizationSignup;
