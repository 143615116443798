import React, { useState, useEffect, useLayoutEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./manager-dashboard.css";
import FeedbackRequestForm from "../Send_Feedback_Request/Send_Feedback_Request";
import { FidgetSpinner } from "react-loader-spinner";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Select, MenuItem } from "@mui/material";

const Managerdashboard = ({ companyName }) => {
  const navigate = useNavigate();
  const [employeeDetails, setEmployeeDetails] = useState({
    fullName: "",
    empId: "",
    dob: "",
    role: "",
    profilePic:
      localStorage.getItem("adminProfilePic") || "./Images/Default.png",
  });
  const [selectedCategory, setSelectedCategory] = useState("");
  const [categories, setCategories] = useState([]);
  const [customPrompt, setCustomPrompt] = useState("");
  const [defaultResponse, setDefaultResponse] = useState("");
  const [response, setResponse] = useState("");
  const [isFeedbackPopupVisible, setIsFeedbackPopupVisible] = useState(false);
  const [isImageUploadModalVisible, setIsImageUploadModalVisible] =
    useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isLoadingFeedback, setIsLoadingFeedback] = useState(false);
  const managersId = localStorage.getItem("manager_id");
  const [content, setContent] = useState("");
  const handleInput = (e) => {
    setContent(e.currentTarget.textContent);
  };

  useEffect(() => {
    const fetchManagerDetails = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/employeeRoutes/getEmployeeById?id=${managersId}`
        );
        const data = await response.json();

        if (response.ok && data.statusCode === 200) {
          const { first_name, last_name, id, dob, role, profile_pic } =
            data.result;
          setEmployeeDetails({
            fullName: `${first_name} ${last_name}`,
            empId: id,
            dob: dob ? new Date(dob).toLocaleDateString() : "N/A",
            role: role || "N/A",
            profilePic: profile_pic || "./Images/Default.png",
          });

          localStorage.setItem(
            "adminProfilePic",
            profile_pic || "./Images/Default.png"
          );
        } else {
          console.error("Error fetching employee details:", data.message);
        }
      } catch (error) {
        console.error("Error during API call:", error);
      }
    };

    fetchManagerDetails();
  }, []);

  useEffect(() => {
    const fetchcategories = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/feedback/getCategoriesByManagerId?manager_id=${managersId}`
        );
        const data = await response.json();
        if (response.ok && data.statusCode === 200) {
          setCategories(data.result.categories);
          setSelectedCategory(data.result.categories[0]);
        } else {
          console.error("Error fetching categories details:", data.message);
        }
      } catch (error) {
        console.error("Error during API call:", error);
      }
    };
    fetchcategories();
  }, []);

  const fetchFeedback = async (category) => {
    try {
      const response = await fetch(
        `${
          process.env.REACT_APP_BACKEND_URL
        }/feedback/getFeedBackByManagerId?manager_id=${managersId}&selectedCategory=${encodeURIComponent(
          category
        )}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );

      const contentType = response.headers.get("content-type");

      if (!response.ok) {
        toast.error("Failed to fetch feedback. Please check the API endpoint.");
        return;
      }

      if (contentType && contentType.includes("application/json")) {
        const data = await response.json();

        if (data.statusCode === 200) {
          const summaryData = data.result.Response || "No summary available.";
          setDefaultResponse(summaryData);
        } else {
          toast.error("Failed to fetch feedback.");
        }
      } else {
        toast.error("Unexpected response format. Please check the API.");
      }
    } catch (error) {
      console.error("Error fetching feedback:", error);
      toast.error("An error occurred while fetching feedback.");
    } finally {
      setIsLoadingFeedback(false);
    }
  };

  useLayoutEffect(() => {
    setIsLoadingFeedback(true);
    setResponse("");
    setDefaultResponse("");

    if (selectedCategory) {
      fetchFeedback(selectedCategory);
    } else {
      setIsLoadingFeedback(false);
    }
  }, [selectedCategory, managersId]);

  const handleCategoryChange = async (e) => {
    const newCategory = e.target.value;
    setCustomPrompt("");
    setSelectedCategory(newCategory);

    if (!managersId) {
      toast.error("Manager ID not found.");
      return;
    }

    if (!newCategory) {
      toast.error("Please select a category.");
      return;
    }
  };

  const handleQueryChange = (e) => {
    setCustomPrompt(e.target.value);
  };

  const handleSubmit = async () => {
    setIsLoadingFeedback(true);
    if (!managersId) {
      toast.error("Manager ID not found.");
      return;
    }

    if (!selectedCategory || !customPrompt) {
      toast.error("Please select a category and enter a query.");
      return;
    }

    try {
      const response = await fetch(
        `${
          process.env.REACT_APP_BACKEND_URL
        }/feedback/getFeedBackByManagerId?manager_id=${managersId}&customPrompt=${encodeURIComponent(
          customPrompt
        )}&selectedCategory=${encodeURIComponent(selectedCategory)}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );

      const contentType = response.headers.get("content-type");

      if (!response.ok) {
        toast.error("Failed to fetch feedback. Please check the API endpoint.");
        return;
      }

      if (contentType && contentType.includes("application/json")) {
        const data = await response.json();

        if (data.statusCode === 200) {
          const summaryData = data.result.Response || "No summary available.";
          setResponse(summaryData);
          toast.success("Response Fetched Successfully.");
        } else {
          toast.error("Failed to fetch feedback.");
        }
      } else {
        toast.error("Unexpected response format. Please check the API.");
      }
    } catch (error) {
      console.error("Error fetching feedback:", error);
      toast.error("An error occurred while fetching feedback.");
    } finally {
      setIsLoadingFeedback(false);
    }
  };

  const handleImageUpload = async () => {
    if (!selectedFile) {
      toast.error("Please select a file first.");
      return;
    }

    const employeeId = localStorage.getItem("employee_id");

    const formData = new FormData();
    formData.append("file", selectedFile);
    formData.append("id", employeeId);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/employeeRoutes/uploadPhotoForEmployee`,
        {
          method: "POST",
          body: formData,
        }
      );
      const data = await response.json();

      if (data.statusCode === 200) {
        toast.success("Image uploaded successfully");

        const uploadedPicUrl = data.result.profile_pic;
        localStorage.setItem("adminProfilePic", uploadedPicUrl);

        setTimeout(() => {
          window.location.reload();
        }, 1500);
      } else {
        toast.error("Failed to upload image");
      }
    } catch (error) {
      toast.error("Error uploading image");
    }
  };

  return (
    <div>
      <ToastContainer />
      {isLoadingFeedback && (
        <div className="modal-overlay">
          <div className="modal-content">
            <FidgetSpinner
              visible={true}
              height="80"
              width="80"
              ariaLabel="fidget-spinner-loading"
              backgroundColor="blue"
            />
          </div>
        </div>
      )}
      <div className="admin">
        <div className="admin-details">
          <div className="admin-profile">
            <img
              src={employeeDetails.profilePic}
              className="admin-profile-pic"
              alt="profile_pic"
            />
            <img
              src="./Images/Upload.png"
              className="upload-admin-pic"
              alt="upload_pic"
              onClick={() => setIsImageUploadModalVisible(true)}
            />
            <div className="admin-del-container">
              <div className="admin-det-lables">
                <p>Full Name</p>
                <p>EMP ID</p>
                <p>DOB</p>
                <p>Role</p>
              </div>
              <div className="admin-det-columns">
                <p>:</p>
                <p>:</p>
                <p>:</p>
                <p>:</p>
              </div>
              <div className="admin-det-values">
                <p>{employeeDetails.fullName}</p>
                <p>{employeeDetails.empId}</p>
                <p>{employeeDetails.dob}</p>
                <p>{employeeDetails.role}</p>
              </div>
            </div>
          </div>
          <div className="admin-company-details">
            <div className="admin-company-details-pairs">
              <img
                src="./Images/send-feedback.png"
                className="feed-back-pic"
                alt="Feedback"
                onClick={() => setIsFeedbackPopupVisible(true)}
              />
            </div>
          </div>
        </div>
        <div className="admin-additions">
          <div className="input-group0">
            <label htmlFor="category-select">Category</label>
            <div className="Recieved-feedbacks-from-employees">
              <Select
                value={selectedCategory}
                onChange={handleCategoryChange}
                variant="outlined"
                className="small-dropdown"
                style={{ fontSize: "12px" }}
              >
                {categories.map((category, index) => (
                  <MenuItem
                    key={index}
                    value={category}
                    style={{ fontSize: "12px" }}
                  >
                    {category}
                  </MenuItem>
                ))}
              </Select>
            </div>
          </div>

          <div className="input-group1">
            <label htmlFor="query-text">Query</label>
            <div className="query-container">
              <input type="text"
                // contenteditable="true"
                // onInput={(e) => setCustomPrompt(e.currentTarget.textContent)}
                id="query-textarea"
                className="query-textarea"
                placeholder="Enter your query here..."
                rows="4"
                value={customPrompt}
                onChange={handleQueryChange}
              />
              <div>
                {customPrompt && (
                  <img
                    src="../Images/send.png"
                    onClick={handleSubmit}
                    className="query-btn1"
                  />
                )}
              </div>
            </div>
          </div>

          {response ? (
            <div className="feedback-response">
              <label htmlFor="query-textarea">Response</label>
              <p>{response}</p>
            </div>
          ) : (
            <div className="feedback-response">
              <label htmlFor="query-textarea">Response</label>
              {defaultResponse === "No summary generated." ? (
                <p>{defaultResponse}</p>
              ) : (
                defaultResponse
                  .replace("Here are the 5 bullet points:\n\n", "")
                  .split("•")
                  .slice(1)
                  .map((point, index) => <p key={index}>• {point.trim()}</p>)
              )}
            </div>
          )}
        </div>
      </div>

      {isFeedbackPopupVisible && (
        <FeedbackRequestForm onClose={() => setIsFeedbackPopupVisible(false)} />
      )}

      {isImageUploadModalVisible && (
        <div className="image-upload-modal-overlay">
          <div className="image-upload-modal-content">
            <h3>Upload Profile Picture</h3>
            <input
              type="file"
              onChange={(e) => setSelectedFile(e.target.files[0])}
            />
            <button className="Image-upload-button" onClick={handleImageUpload}>
              Upload
            </button>
            <button
              className="Image-cancel-button"
              onClick={() => setIsImageUploadModalVisible(false)}
            >
              Cancel
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Managerdashboard;
