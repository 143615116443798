// import React, { useState, useEffect } from 'react';
// import { useNavigate, useLocation } from 'react-router-dom';
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import { FidgetSpinner } from 'react-loader-spinner';
// import {
//   Box,
//   TextField,
//   Button,
//   Dialog,
//   DialogContent,
//   DialogActions,
//   Typography,
//   InputAdornment,
//   Card,
//   CardContent,
//   Paper,
//   IconButton
// } from '@mui/material';
// import { FaEye, FaEyeSlash, FaCommentAlt, FaClipboardList, FaSignOutAlt } from 'react-icons/fa';

// const EmployeeLogin = ({ onLogin }) => {
//   const [email, setEmail] = useState('');
//   const [password, setPassword] = useState('');
//   const [isLoading, setIsLoading] = useState(false);
//   const [showPassword, setShowPassword] = useState(false);
//   const [showForgotPasswordPopup, setShowForgotPasswordPopup] = useState(false);
//   const [forgotPasswordEmail, setForgotPasswordEmail] = useState('');
//   const [isLoggedIn, setIsLoggedIn] = useState(false);
//   const [employeeData, setEmployeeData] = useState(null);
//   const [feedbackData, setFeedbackData] = useState(null);
//   const [showFeedbackCategories, setShowFeedbackCategories] = useState(false);
//   const [feedbackId, setFeedbackId] = useState(null);
//   const navigate = useNavigate();
//   const location = useLocation();


//   const VALID_EMAIL = 'employee@company.com';
//   const VALID_PASSWORD = 'password123';


//   const MOCK_EMPLOYEE_DATA = {
//     result: {
//       id: 338,
//       first_name: "Demo",
//       last_name: "User",
//       email: "employee@company.com",
//       access_token: "mock-token-for-demo-purposes",
//       organization_id: 83
//     }
//   };

//   useEffect(() => {
//     const checkLoginStatus = () => {
//       try {
//         const storedLoginStatus = localStorage.getItem('isLoggedIn');
//         const storedEmployeeId = localStorage.getItem('employeeId');

//         if (storedLoginStatus === 'true' && storedEmployeeId) {
//           setIsLoggedIn(true);
//           setEmployeeData({
//             result: {
//               id: storedEmployeeId,
//               organization_id: localStorage.getItem('organizationId') || 83
//             }
//           });
//         } else {
//           setIsLoggedIn(false);
//           setEmployeeData(null);
//         }
//       } catch (error) {
//         console.error('Error accessing localStorage:', error);
//         setIsLoggedIn(false);
//         setEmployeeData(null);
//       }
//     };

//     checkLoginStatus(); 

    
//     const handleStorageChange = (event) => {
//       if (event.key === 'isLoggedIn' || event.key === 'employeeId') {
//         checkLoginStatus();
//       }
//     };

//     window.addEventListener('storage', handleStorageChange);

//     return () => {
//       window.removeEventListener('storage', handleStorageChange);
//     };
//   }, []);


//   useEffect(() => {
//     console.log('Login state updated:', { isLoggedIn, email, password });
//   }, [isLoggedIn, email, password]);

//   const createFeedbackForTesting = async (employeeId, organizationId) => {
//     setIsLoading(true);
//     try {
//       const apiUrl = `${process.env.REACT_APP_BACKEND_URL || 'http://localhost:3000'}/feedback/createFeedbackForTesting`;


//       const payload = {
//         employee_id: Number(employeeId) || 341,
//         organization_id: Number(organizationId) || 83,
//         manager_id: 340,
//         roles: [],
//         categories: ["General Employee Experience"],
//         request_frequency: "once",
//         request_to: "one",
//         other_feedback: "General Employee Experience"
//       };

//       console.log('Request payload:', payload);

//       const token = localStorage.getItem('token') || '';

//       const response = await fetch(apiUrl, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//           'Authorization': token ? `Bearer ${token}` : ''
//         },
//         body: JSON.stringify(payload)
//       });

//       const responseText = await response.text();
//       console.log('Raw response:', responseText);

//       let data;
//       try {
//         data = JSON.parse(responseText);
//       } catch (e) {
//         console.error('Failed to parse response as JSON:', e);
//         throw new Error(`Server responded with ${response.status}: ${responseText}`);
//       }

//       if (!response.ok || data.statusCode !== 200) {
//         throw new Error(data.message || `Server responded with ${response.status}: ${response.statusText}`);
//       }

//       console.log('Feedback created successfully:', data);


//       console.log('Full response structure:', JSON.stringify(data, null, 2));


//       let feedbackId = null;

//       if (data.result && data.result.id) {
//         feedbackId = data.result.id;
//         console.log('Found feedback ID in data.result.id:', feedbackId);
//       } else if (data.result && data.result.feedback_id) {
//         feedbackId = data.result.feedback_id;
//         console.log('Found feedback ID in data.result.feedback_id:', feedbackId);
//       } else if (data.result && data.result.feedbackId) {
//         feedbackId = data.result.feedbackId;
//         console.log('Found feedback ID in data.result.feedbackId:', feedbackId);
//       } else if (data.id) {
//         feedbackId = data.id;
//         console.log('Found feedback ID in data.id:', feedbackId);
//       } else if (data.feedback_id) {
//         feedbackId = data.feedback_id;
//         console.log('Found feedback ID in data.feedback_id:', feedbackId);
//       } else if (data.feedbackId) {
//         feedbackId = data.feedbackId;
//         console.log('Found feedback ID in data.feedbackId:', feedbackId);
//       }

//       if (feedbackId) {
//         setFeedbackId(feedbackId);
//         localStorage.setItem('currentFeedbackId', feedbackId.toString());
//         console.log('Feedback ID set and stored:', feedbackId);


//         await getFeedbackById(feedbackId);
//       } else {
//         console.warn('Could not find feedback ID in response');
//         toast.warning('Feedback created but ID not found in response');
//       }

//       toast.success('Feedback request created successfully');
//       return data;
//     } catch (error) {
//       console.error('Error creating feedback:', error);
//       toast.error(`Error: ${error.message}. Please try again.`);
//       return null;
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   const getFeedbackById = async (id) => {
//     setIsLoading(true);
//     try {
//       const apiUrl = `${process.env.REACT_APP_BACKEND_URL || 'http://localhost:3000'}/feedback/getFeedBackByControl?id=${id}`;

//       const token = localStorage.getItem('token') || '';

//       const response = await fetch(apiUrl, {
//         method: 'GET',
//         headers: {
//           'Content-Type': 'application/json',
//           'Authorization': token ? `Bearer ${token}` : ''
//         }
//       });

//       if (!response.ok) {
//         throw new Error(`Server responded with ${response.status}: ${response.statusText}`);
//       }

//       const data = await response.json();

//       if (data.statusCode !== 200) {
//         throw new Error(data.message || 'Failed to fetch feedback data');
//       }

//       console.log('Feedback data fetched successfully:', data);
//       setFeedbackData(data.result);
//       localStorage.setItem('currentFeedback', JSON.stringify(data.result));

//       return data;
//     } catch (error) {
//       console.error('Error fetching feedback data:', error);
//       toast.error('Failed to fetch feedback data. Using demo data.');


//       const mockData = {
//         statusCode: 200,
//         status: "SUCCESS",
//         message: "Feedbacks returned successfully",
//         result: {
//           id: id || 2696,
//           organization_id: 83,
//           employee_id: employeeData?.result?.id || 338,
//           manager_id: 29,
//           is_audited: false,
//           is_manager_read: false,
//           is_anonymous: false,
//           is_employee_feeded: false,
//           createdAt: new Date().toISOString(),
//           updatedAt: new Date().toISOString(),
//           categories: [
//             {
//               id: 5113,
//               feedback_control_id: id || 2696,
//               category: "Work Environment",
//               meta_data: null,
//               is_text: false,
//               text: null,
//               voice_record: null,
//               feedback: null,
//               summary: null,
//               sentiment: null,
//               percentage: null,
//               createdAt: new Date().toISOString(),
//               updatedAt: new Date().toISOString()
//             },
//             {
//               id: 5114,
//               feedback_control_id: id || 2696,
//               category: "Management",
//               meta_data: null,
//               is_text: false,
//               text: null,
//               voice_record: null,
//               feedback: null,
//               summary: null,
//               sentiment: null,
//               percentage: null,
//               createdAt: new Date().toISOString(),
//               updatedAt: new Date().toISOString()
//             },
//             {
//               id: 5115,
//               feedback_control_id: id || 2696,
//               category: "General Employee Experience",
//               meta_data: null,
//               is_text: false,
//               text: null,
//               voice_record: null,
//               feedback: null,
//               summary: null,
//               sentiment: null,
//               percentage: null,
//               createdAt: new Date().toISOString(),
//               updatedAt: new Date().toISOString()
//             }
//           ]
//         }
//       };

//       setFeedbackData(mockData.result);
//       localStorage.setItem('currentFeedback', JSON.stringify(mockData.result));

//       return mockData;
//     } finally {
//       setIsLoading(false);
//     }
//   };


//   const createEmployeeForTesting = async () => {
//     try {

//       const payload = {
//         first_name: "testing",
//         last_name: "kumar",
//         email: "testing@gmail.com",
//         organization_id: 83,
//         manager_id: 29,
//         role: "Developer",
//         gender: "male",
//         dob: "1997-09-23",
//         doj: "2022-01-01",
//         mobile_number: "9347966567"
//       };


//       if (!process.env.REACT_APP_BACKEND_URL) {
//         console.warn('Backend URL environment variable is not set');
//         throw new Error('API configuration missing');
//       }


//       const apiUrl = `${process.env.REACT_APP_BACKEND_URL}/employeeRoutes/createEmployeeForTesting`;
//       console.log('Making API request to:', apiUrl);


//       const token = localStorage.getItem('token');
//       if (!token) {
//         console.warn('No authentication token found in localStorage');
//       }

//       const response = await fetch(apiUrl, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//           'Authorization': token ? `Bearer ${token}` : ''
//         },
//         body: JSON.stringify(payload)
//       });


//       if (!response.ok) {
//         const textResponse = await response.text();
//         console.error('API Error Response:', textResponse);


//         if (response.status === 404) {
//           throw new Error('API endpoint not found. Using mock data instead.');
//         }

//         throw new Error(`Server responded with ${response.status}: ${response.statusText}`);
//       }

//       const data = await response.json();

//       if (data.statusCode !== 200) {
//         console.error('API Error:', data);
//         throw new Error(data.message || 'Failed to create employee');
//       }

//       return data;
//     } catch (error) {
//       console.error('Error creating employee:', error);


//       console.log('Using mock employee data as fallback');
//       return MOCK_EMPLOYEE_DATA;
//     }
//   };

//   const handleLoginClick = async () => {
//     console.log('Login attempt with:', { email, password });

//     if (!email || !password) {
//       console.log('Missing credentials');
//       toast.error('Please enter both email and password');
//       return;
//     }

//     setIsLoading(true);

//     try {

//       const trimmedEmail = email.trim();
//       const trimmedPassword = password.trim();

//       console.log('Checking against:', { VALID_EMAIL, VALID_PASSWORD });
//       console.log('Comparison result:', trimmedEmail === VALID_EMAIL && trimmedPassword === VALID_PASSWORD);


//       if (trimmedEmail === VALID_EMAIL && trimmedPassword === VALID_PASSWORD) {
//         await new Promise(resolve => setTimeout(resolve, 1000));


//         const employeeData = await createEmployeeForTesting();
//         setEmployeeData(employeeData);

//         try {

//           localStorage.setItem('isLoggedIn', 'true');
//           localStorage.setItem('userRole', 'Employee');
//           localStorage.setItem('userEmail', email);
//           localStorage.setItem('employeeId', employeeData.result.id);


//           if (employeeData.result && employeeData.result.organization_id) {
//             localStorage.setItem('organizationId', employeeData.result.organization_id);
//           }


//           if (employeeData.result && employeeData.result.access_token) {
//             localStorage.setItem('token', employeeData.result.access_token);
//           }
//         } catch (storageError) {
//           console.error('Error storing data in localStorage:', storageError);
//           toast.warning('Login successful, but browser storage is restricted. Some features may not work properly.');
//         }

//         if (onLogin) {
//           onLogin('Employee');
//         }

//         setIsLoggedIn(true);
//         console.log('Login successful, state updated:', { isLoggedIn: true });
//         toast.success('You have logged in successfully');
//       } else {
//         console.log('Invalid credentials');
//         toast.error('Please enter valid email/password');
//       }
//     } catch (error) {
//       console.error('Error during login:', error);
//       toast.error(error.message || 'An error occurred during login. Please try again.');
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   const handleViewFeedback = () => {
//     if (feedbackId) {
//       navigate(`/employee-feedback?feedback_id=${feedbackId}`);
//     }
//   };

//   const togglePasswordVisibility = () => {
//     setShowPassword((prevShowPassword) => !prevShowPassword);
//   };

//   const handleForgotPasswordClick = () => {
//     setShowForgotPasswordPopup(true);
//   };

//   const handleCloseForgotPasswordPopup = () => {
//     setShowForgotPasswordPopup(false);
//   };

//   const handleSubmitForgotPassword = async () => {
//     if (!forgotPasswordEmail) {
//       toast.error('Please enter a valid email address.');
//       return;
//     }

//     const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
//     if (!emailRegex.test(forgotPasswordEmail)) {
//       toast.error('Please enter a valid email address.');
//       return;
//     }

//     setIsLoading(true);
//     try {
//       await new Promise(resolve => setTimeout(resolve, 1000));
//       toast.success('Verification mail sent to reset password.');
//       handleCloseForgotPasswordPopup();
//     } catch (error) {
//       console.error('Error:', error);
//       toast.error('An error occurred while sending the reset password email.');
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   const handleLogout = () => {
    
//     localStorage.removeItem('isLoggedIn');
//     localStorage.removeItem('userRole');
//     localStorage.removeItem('userEmail');
//     localStorage.removeItem('employeeId');
//     localStorage.removeItem('organizationId');
//     localStorage.removeItem('token');
//     localStorage.removeItem('currentFeedbackId');
//     localStorage.removeItem('currentFeedback');

    
//     setIsLoggedIn(false);
//     setEmployeeData(null);
//     setFeedbackData(null);
//     setFeedbackId(null);

    
//     if (onLogin) {
//       onLogin(null);
//     }

    
//     toast.success('You have been logged out successfully');
//   };

//   return (
//     <Box
//       sx={{
//         background: 'linear-gradient(135deg, #6a11cb 0%, #2575fc 100%)',
//         minHeight: '100vh',
//         display: 'flex',
//         justifyContent: 'center',
//         alignItems: 'center',
//         padding: '20px',
//         boxSizing: 'border-box',
//         position: 'relative', 
//       }}
//     >
//       <ToastContainer />

//       {isLoading && (
//         <Box
//           sx={{
//             position: 'fixed',
//             top: 0,
//             left: 0,
//             right: 0,
//             bottom: 0,
//             backgroundColor: 'rgba(0, 0, 0, 0.5)',
//             display: 'flex',
//             justifyContent: 'center',
//             alignItems: 'center',
//             zIndex: 1000,
//           }}
//         >
//           <Box
//             sx={{
//               backgroundColor: 'white',
//               padding: '40px',
//               borderRadius: '10px',
//               boxShadow: '0 5px 15px rgba(0, 0, 0, 0.2)',
//             }}
//           >
//             <FidgetSpinner
//               visible={true}
//               height="80"
//               width="80"
//               ariaLabel="fidget-spinner-loading"
//               backgroundColor="#2575fc"
//               wrapperStyle={{}}
//               wrapperClass=""
//             />
//           </Box>
//         </Box>
//       )}

//       {!isLoggedIn ? (
//         <Card
//           sx={{
//             width: '100%',
//             maxWidth: '450px',
//             borderRadius: '20px',
//             boxShadow: '0 15px 35px rgba(0, 0, 0, 0.2)',
//             overflow: 'hidden',
//             background: 'rgba(255, 255, 255, 0.95)',
//             backdropFilter: 'blur(10px)',
//           }}
//         >
//           <Box
//             sx={{
//               background: 'linear-gradient(135deg, #4a00e0 0%, #8e2de2 100%)',
//               padding: '30px 20px',
//               textAlign: 'center',
//             }}
//           >
//             <Typography variant="h4" sx={{ color: 'white', fontWeight: 'bold', marginBottom: '10px' }}>
//               Employee Login
//             </Typography>
//             <Typography variant="body1" sx={{ color: 'rgba(255, 255, 255, 0.8)' }}>
//               Access your feedback portal
//             </Typography>
//           </Box>

//           <CardContent sx={{ padding: '30px' }}>
//             <Box
//               component="form"
//               onSubmit={(e) => {
//                 e.preventDefault();
//                 handleLoginClick();
//               }}
//               sx={{ '& > :not(style)': { mb: 3 } }}
//             >
//               <TextField
//                 fullWidth
//                 label="Email"
//                 variant="outlined"
//                 value={email}
//                 onChange={(e) => setEmail(e.target.value)}
//                 placeholder="Enter your email"
//                 sx={{
//                   '& .MuiOutlinedInput-root': {
//                     borderRadius: '10px',
//                     '&:hover fieldset': {
//                       borderColor: '#8e2de2',
//                     },
//                     '&.Mui-focused fieldset': {
//                       borderColor: '#4a00e0',
//                     },
//                   },
//                 }}
//               />

//               <TextField
//                 fullWidth
//                 label="Password"
//                 variant="outlined"
//                 type={showPassword ? 'text' : 'password'}
//                 value={password}
//                 onChange={(e) => setPassword(e.target.value)}
//                 placeholder="Enter your password"
//                 InputProps={{
//                   endAdornment: (
//                     <InputAdornment position="end">
//                       <Box
//                         component="button"
//                         type="button"
//                         onClick={togglePasswordVisibility}
//                         sx={{
//                           background: 'none',
//                           border: 'none',
//                           cursor: 'pointer',
//                           color: '#666',
//                           padding: 0,
//                         }}
//                       >
//                         {showPassword ? <FaEyeSlash /> : <FaEye />}
//                       </Box>
//                     </InputAdornment>
//                   ),
//                 }}
//                 sx={{
//                   '& .MuiOutlinedInput-root': {
//                     borderRadius: '10px',
//                     '&:hover fieldset': {
//                       borderColor: '#8e2de2',
//                     },
//                     '&.Mui-focused fieldset': {
//                       borderColor: '#4a00e0',
//                     },
//                   },
//                 }}
//               />

//               <Button
//                 type="submit"
//                 fullWidth
//                 variant="contained"
//                 sx={{
//                   background: 'linear-gradient(135deg, #4a00e0 0%, #8e2de2 100%)',
//                   color: 'white',
//                   padding: '12px',
//                   borderRadius: '10px',
//                   fontWeight: 'bold',
//                   textTransform: 'none',
//                   fontSize: '16px',
//                   '&:hover': {
//                     background: 'linear-gradient(135deg, #3a00b0 0%, #7e1dd2 100%)',
//                     boxShadow: '0 8px 15px rgba(142, 45, 226, 0.3)',
//                   },
//                 }}
//               >
//                 Login
//               </Button>

//               <Typography variant="body2" sx={{ textAlign: 'center', color: '#666', marginTop: '10px' }}>
//                 Demo credentials: employee@company.com / password123
//               </Typography>
//             </Box>
//           </CardContent>
//         </Card>
//       ) : (
//         <>

//           <Box
//             sx={{
//               position: 'absolute',
//               top: '20px',
//               right: '20px',
//               zIndex: 10,
//             }}
//           >
//             <Button
//               variant="contained"
//               onClick={handleLogout}
//               startIcon={<FaSignOutAlt />}
//               sx={{
//                 background: 'rgba(255, 255, 255, 0.9)',
//                 color: '#4a00e0',
//                 padding: '8px 16px',
//                 borderRadius: '10px',
//                 fontWeight: 'bold',
//                 textTransform: 'none',
//                 fontSize: '14px',
//                 boxShadow: '0 4px 10px rgba(0, 0, 0, 0.2)',
//                 '&:hover': {
//                   background: 'rgba(255, 255, 255, 1)',
//                   boxShadow: '0 6px 12px rgba(0, 0, 0, 0.3)',
//                 },
//               }}
//             >
//               Logout
//             </Button>
//           </Box>

//           <Box
//             sx={{
//               width: '100%',
//               maxWidth: '900px',
//               display: 'flex',
//               flexDirection: 'column',
//               gap: '20px',
//             }}
//           >

//             <Card
//               sx={{
//                 borderRadius: '20px',
//                 boxShadow: '0 15px 35px rgba(0, 0, 0, 0.2)',
//                 overflow: 'hidden',
//                 background: 'rgba(255, 255, 255, 0.95)',
//                 backdropFilter: 'blur(10px)',
//                 padding: '30px',
//                 textAlign: 'center',
//               }}
//             >
//               <Box
//                 sx={{
//                   display: 'flex',
//                   flexDirection: 'column',
//                   alignItems: 'center',
//                   gap: '20px',
//                 }}
//               >
//                 <Box
//                   sx={{
//                     width: '100px',
//                     height: '100px',
//                     borderRadius: '50%',
//                     background: 'linear-gradient(135deg, #4a00e0 0%, #8e2de2 100%)',
//                     display: 'flex',
//                     justifyContent: 'center',
//                     alignItems: 'center',
//                   }}
//                 >
//                   <FaCommentAlt size={40} color="white" />
//                 </Box>

//                 <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#333' }}>
//                   Welcome to Your Feedback Portal!
//                 </Typography>

//                 <Typography variant="body1" sx={{ color: '#666', maxWidth: '600px', margin: '0 auto' }}>
//                   Your opinions matter to us. This platform allows you to provide valuable feedback that helps us improve and grow together. Click the button below to get started.
//                 </Typography>

//                 <Button
//                   variant="contained"
//                   onClick={() => createFeedbackForTesting(employeeData?.result?.id, employeeData?.result?.organization_id)}
//                   startIcon={<FaClipboardList />}
//                   sx={{
//                     background: 'linear-gradient(135deg, #4a00e0 0%, #8e2de2 100%)',
//                     color: 'white',
//                     padding: '12px 24px',
//                     borderRadius: '10px',
//                     fontWeight: 'bold',
//                     textTransform: 'none',
//                     fontSize: '16px',
//                     '&:hover': {
//                       background: 'linear-gradient(135deg, #3a00b0 0%, #7e1dd2 100%)',
//                       boxShadow: '0 8px 15px rgba(142, 45, 226, 0.3)',
//                     },
//                   }}
//                 >
//                   View Feedback Categories
//                 </Button>
//               </Box>
//             </Card>


//             {feedbackData && (
//               <Card
//                 sx={{
//                   borderRadius: '20px',
//                   boxShadow: '0 15px 35px rgba(0, 0, 0, 0.2)',
//                   overflow: 'hidden',
//                   background: 'rgba(255, 255, 255, 0.95)',
//                   backdropFilter: 'blur(10px)',
//                   padding: '30px',
//                 }}
//               >
//                 <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#333', marginBottom: '20px' }}>
//                   Feedback Categories
//                 </Typography>

//                 <Box sx={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
//                   {feedbackData.categories.map((category, index) => (
//                     <Paper
//                       key={category.id || index}
//                       elevation={3}
//                       sx={{
//                         padding: '15px',
//                         borderRadius: '12px',
//                         background: 'linear-gradient(to right, #f5f7fa, #c3cfe2)',
//                         display: 'flex',
//                         alignItems: 'center',
//                         gap: '15px',
//                       }}
//                     >
//                       <Box
//                         sx={{
//                           width: '40px',
//                           height: '40px',
//                           borderRadius: '50%',
//                           background: 'linear-gradient(135deg, #4a00e0 0%, #8e2de2 100%)',
//                           display: 'flex',
//                           justifyContent: 'center',
//                           alignItems: 'center',
//                           flexShrink: 0,
//                         }}
//                       >
//                         <Typography variant="body1" sx={{ color: 'white', fontWeight: 'bold' }}>
//                           {index + 1}
//                         </Typography>
//                       </Box>

//                       <Typography variant="body1" sx={{ fontWeight: 'medium', color: '#333' }}>
//                         {category.category}
//                       </Typography>
//                     </Paper>
//                   ))}
//                 </Box>

//                 <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '30px' }}>
//                   <Button
//                     variant="contained"
//                     onClick={handleViewFeedback}
//                     sx={{
//                       background: 'linear-gradient(135deg, #4a00e0 0%, #8e2de2 100%)',
//                       color: 'white',
//                       padding: '12px 24px',
//                       borderRadius: '10px',
//                       fontWeight: 'bold',
//                       textTransform: 'none',
//                       fontSize: '16px',
//                       '&:hover': {
//                         background: 'linear-gradient(135deg, #3a00b0 0%, #7e1dd2 100%)',
//                         boxShadow: '0 8px 15px rgba(142, 45, 226, 0.3)',
//                       },
//                     }}
//                   >
//                     Provide Feedback
//                   </Button>
//                 </Box>
//               </Card>
//             )}
//           </Box>
//         </>
//       )}

//     </Box>
//   );
// };

// export default EmployeeLogin;

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FidgetSpinner } from 'react-loader-spinner';
import {
  Box,
  TextField,
  Button,
  Typography,
  InputAdornment,
  Card,
  CardContent,
  Paper,
} from '@mui/material';
import { FaEye, FaEyeSlash, FaCommentAlt, FaClipboardList, FaSignOutAlt } from 'react-icons/fa';

const EmployeeLogin = ({ onLogin }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [employeeData, setEmployeeData] = useState(null);
  const [feedbackData, setFeedbackData] = useState(null);
  const [feedbackId, setFeedbackId] = useState(null);
  const [showCategories, setShowCategories] = useState(false); 
  const navigate = useNavigate();
  

  const VALID_EMAIL = 'employee@company.com';
  const VALID_PASSWORD = 'Password1234';

  const MOCK_EMPLOYEE_DATA = {
    result: {
      id: 341,
      first_name: "Demo",
      last_name: "User",
      email: "employee@company.com",
      access_token: "mock-token-for-demo-purposes",
      organization_id: 83
    }
  };

  useEffect(() => {
    const checkLoginStatus = () => {
      try {
        const storedLoginStatus = localStorage.getItem('isLoggedIn');
        const storedEmployeeId = localStorage.getItem('employeeId');

        if (storedLoginStatus === 'true' && storedEmployeeId) {
          setIsLoggedIn(true);
          setEmployeeData({
            result: {
              id: storedEmployeeId,
              organization_id: localStorage.getItem('organizationId') || 83
            }
          });
        } else {
          setIsLoggedIn(false);
          setEmployeeData(null);
        }
      } catch (error) {
        console.error('Error accessing localStorage:', error);
        setIsLoggedIn(false);
        setEmployeeData(null);
      }
    };

    checkLoginStatus();
    
    const handleStorageChange = (event) => {
      if (event.key === 'isLoggedIn' || event.key === 'employeeId') {
        checkLoginStatus();
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  useEffect(() => {
    console.log('Login state updated:', { isLoggedIn, email, password });
  }, [isLoggedIn, email, password]);

  const createFeedbackForTesting = async (employeeId, organizationId) => {
    setIsLoading(true);
    try {
      const apiUrl = `${process.env.REACT_APP_BACKEND_URL || 'http://localhost:3000'}/feedback/createFeedbackForTesting`;

      const payload = {
        employee_id: 341,
        organization_id: Number(organizationId) || 83,
        manager_id: 340,
        roles: [],
        categories: ["General Employee Experience"],
        request_frequency: "once",
        request_to: "one",
        other_feedback: "General Employee Experience"
      };

      console.log('Request payload:', payload);

      const token = localStorage.getItem('token') || '';

      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token ? `Bearer ${token}` : ''
        },
        body: JSON.stringify(payload)
      });

      const responseText = await response.text();
      console.log('Raw response:', responseText);

      let data;
      try {
        data = JSON.parse(responseText);
      } catch (e) {
        console.error('Failed to parse response as JSON:', e);
        throw new Error(`Server responded with ${response.status}: ${responseText}`);
      }

      if (!response.ok || data.statusCode !== 200) {
        throw new Error(data.message || `Server responded with ${response.status}: ${response.statusText}`);
      }

      console.log('Feedback created successfully:', data);
      console.log('Full response structure:', JSON.stringify(data, null, 2));

      let feedbackId = null;

      if (data.result && data.result.id) {
        feedbackId = data.result.id;
        console.log('Found feedback ID in data.result.id:', feedbackId);
      } else if (data.result && data.result.feedback_id) {
        feedbackId = data.result.feedback_id;
        console.log('Found feedback ID in data.result.feedback_id:', feedbackId);
      } else if (data.result && data.result.feedbackId) {
        feedbackId = data.result.feedbackId;
        console.log('Found feedback ID in data.result.feedbackId:', feedbackId);
      } else if (data.id) {
        feedbackId = data.id;
        console.log('Found feedback ID in data.id:', feedbackId);
      } else if (data.feedback_id) {
        feedbackId = data.feedback_id;
        console.log('Found feedback ID in data.feedback_id:', feedbackId);
      } else if (data.feedbackId) {
        feedbackId = data.feedbackId;
        console.log('Found feedback ID in data.feedbackId:', feedbackId);
      }

      if (feedbackId) {
        setFeedbackId(feedbackId);
        localStorage.setItem('currentFeedbackId', feedbackId.toString());
        console.log('Feedback ID set and stored:', feedbackId);

        await getFeedbackById(feedbackId);
        
       
        setShowCategories(true);
      } else {
        console.warn('Could not find feedback ID in response');
        toast.warning('Feedback created but ID not found in response');
      }

      toast.success('Feedback request created successfully');
      return data;
    } catch (error) {
      console.error('Error creating feedback:', error);
      toast.error(`Error: ${error.message}. Please try again.`);
      return null;
    } finally {
      setIsLoading(false);
    }
  };

  const getFeedbackById = async (id) => {
    setIsLoading(true);
    try {
      const apiUrl = `${process.env.REACT_APP_BACKEND_URL || 'http://localhost:3000'}/feedback/getFeedBackByControl?id=${id}`;

      const token = localStorage.getItem('token') || '';

      const response = await fetch(apiUrl, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token ? `Bearer ${token}` : ''
        }
      });

      if (!response.ok) {
        throw new Error(`Server responded with ${response.status}: ${response.statusText}`);
      }

      const data = await response.json();

      if (data.statusCode !== 200) {
        throw new Error(data.message || 'Failed to fetch feedback data');
      }

      console.log('Feedback data fetched successfully:', data);
      setFeedbackData(data.result);
      localStorage.setItem('currentFeedback', JSON.stringify(data.result));

      return data;
    } catch (error) {
      console.error('Error fetching feedback data:', error);
      toast.error('Failed to fetch feedback data. Using demo data.');

      const mockData = {
        statusCode: 200,
        status: "SUCCESS",
        message: "Feedbacks returned successfully",
        result: {
          id: id || 2696,
          organization_id: 83,
          employee_id: employeeData?.result?.id || 338,
          manager_id: 29,
          is_audited: false,
          is_manager_read: false,
          is_anonymous: false,
          is_employee_feeded: false,
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString(),
          categories: [
            {
              id: 5113,
              feedback_control_id: id || 2696,
              category: "Work Environment",
              meta_data: null,
              is_text: false,
              text: null,
              voice_record: null,
              feedback: null,
              summary: null,
              sentiment: null,
              percentage: null,
              createdAt: new Date().toISOString(),
              updatedAt: new Date().toISOString()
            },
            {
              id: 5114,
              feedback_control_id: id || 2696,
              category: "Management",
              meta_data: null,
              is_text: false,
              text: null,
              voice_record: null,
              feedback: null,
              summary: null,
              sentiment: null,
              percentage: null,
              createdAt: new Date().toISOString(),
              updatedAt: new Date().toISOString()
            },
            {
              id: 5115,
              feedback_control_id: id || 2696,
              category: "General Employee Experience",
              meta_data: null,
              is_text: false,
              text: null,
              voice_record: null,
              feedback: null,
              summary: null,
              sentiment: null,
              percentage: null,
              createdAt: new Date().toISOString(),
              updatedAt: new Date().toISOString()
            }
          ]
        }
      };

      setFeedbackData(mockData.result);
      localStorage.setItem('currentFeedback', JSON.stringify(mockData.result));

      return mockData;
    } finally {
      setIsLoading(false);
    }
  };

  const createEmployeeForTesting = async () => {
    try {
      const payload = {
        first_name: "testing",
        last_name: "kumar",
        email: "testing@gmail.com",
        organization_id: 83,
        manager_id: 29,
        role: "Developer",
        gender: "male",
        dob: "1997-09-23",
        doj: "2022-01-01",
        mobile_number: "9347966567"
      };

      if (!process.env.REACT_APP_BACKEND_URL) {
        console.warn('Backend URL environment variable is not set');
        throw new Error('API configuration missing');
      }

      const apiUrl = `${process.env.REACT_APP_BACKEND_URL}/employeeRoutes/createEmployeeForTesting`;
      console.log('Making API request to:', apiUrl);

      const token = localStorage.getItem('token');
      if (!token) {
        console.warn('No authentication token found in localStorage');
      }

      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token ? `Bearer ${token}` : ''
        },
        body: JSON.stringify(payload)
      });

      if (!response.ok) {
        const textResponse = await response.text();
        console.error('API Error Response:', textResponse);

        if (response.status === 404) {
          throw new Error('API endpoint not found. Using mock data instead.');
        }

        throw new Error(`Server responded with ${response.status}: ${response.statusText}`);
      }

      const data = await response.json();

      if (data.statusCode !== 200) {
        console.error('API Error:', data);
        throw new Error(data.message || 'Failed to create employee');
      }

      return data;
    } catch (error) {
      console.error('Error creating employee:', error);

      console.log('Using mock employee data as fallback');
      return MOCK_EMPLOYEE_DATA;
    }
  };

  const handleLoginClick = async () => {
    console.log('Login attempt with:', { email, password });

    if (!email || !password) {
      console.log('Missing credentials');
      toast.error('Please enter both email and password');
      return;
    }

    setIsLoading(true);

    try {
      const trimmedEmail = email.trim();
      const trimmedPassword = password.trim();

      console.log('Checking against:', { VALID_EMAIL, VALID_PASSWORD });
      console.log('Comparison result:', trimmedEmail === VALID_EMAIL && trimmedPassword === VALID_PASSWORD);

      if (trimmedEmail === VALID_EMAIL && trimmedPassword === VALID_PASSWORD) {
        await new Promise(resolve => setTimeout(resolve, 1000));

        const employeeData = await createEmployeeForTesting();
        setEmployeeData(employeeData);

        try {
          localStorage.setItem('isLoggedIn', 'true');
          localStorage.setItem('userRole', 'Employee');
          localStorage.setItem('userEmail', email);
          localStorage.setItem('employeeId', employeeData.result.id);

          if (employeeData.result && employeeData.result.organization_id) {
            localStorage.setItem('organizationId', employeeData.result.organization_id);
          }

          if (employeeData.result && employeeData.result.access_token) {
            localStorage.setItem('token', employeeData.result.access_token);
          }
        } catch (storageError) {
          console.error('Error storing data in localStorage:', storageError);
          toast.warning('Login successful, but browser storage is restricted. Some features may not work properly.');
        }

        if (onLogin) {
          onLogin('Employee');
        }

        setIsLoggedIn(true);
        console.log('Login successful, state updated:', { isLoggedIn: true });
        toast.success('You have logged in successfully');
      } else {
        console.log('Invalid credentials');
        toast.error('Please enter valid email/password');
      }
    } catch (error) {
      console.error('Error during login:', error);
      toast.error(error.message || 'An error occurred during login. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleViewFeedback = () => {
    if (feedbackId) {
      navigate(`/employee-feedback-testing?feedback_id=${feedbackId}`);
    } else {
      createFeedbackForTesting(employeeData?.result?.id, employeeData?.result?.organization_id)
        .then(data => {
          if (data && feedbackId) {
            navigate(`/employee-feedback-testing?feedback_id=${feedbackId}`);
          } else {
            toast.error("Could not navigate to feedback page. Feedback ID not available.");
          }
        });
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleLogout = () => {
    localStorage.removeItem('isLoggedIn');
    localStorage.removeItem('userRole');
    localStorage.removeItem('userEmail');
    localStorage.removeItem('employeeId');
    localStorage.removeItem('organizationId');
    localStorage.removeItem('token');
    localStorage.removeItem('currentFeedbackId');
    localStorage.removeItem('currentFeedback');
    
    setIsLoggedIn(false);
    setEmployeeData(null);
    setFeedbackId(null);
    setShowCategories(false); 
    
    if (onLogin) {
      onLogin(null);
    }
    
    toast.success('You have been logged out successfully');
  };

  return (
    <Box
      sx={{
        background: 'linear-gradient(135deg, #6a11cb 0%, #2575fc 100%)',
        minHeight: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '20px',
        boxSizing: 'border-box',
        position: 'relative', 
      }}
    >
      <ToastContainer />

      {isLoading && (
        <Box
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1000,
          }}
        >
          <Box
            sx={{
              backgroundColor: 'white',
              padding: '40px',
              borderRadius: '10px',
              boxShadow: '0 5px 15px rgba(0, 0, 0, 0.2)',
            }}
          >
            <FidgetSpinner
              visible={true}
              height="80"
              width="80"
              ariaLabel="fidget-spinner-loading"
              backgroundColor="#2575fc"
              wrapperStyle={{}}
              wrapperClass=""
            />
          </Box>
        </Box>
      )}

      {!isLoggedIn ? (
        <Card
          sx={{
            width: '100%',
            maxWidth: '450px',
            borderRadius: '20px',
            boxShadow: '0 15px 35px rgba(0, 0, 0, 0.2)',
            overflow: 'hidden',
            background: 'rgba(255, 255, 255, 0.95)',
            backdropFilter: 'blur(10px)',
          }}
        >
          <Box
            sx={{
              background: 'linear-gradient(135deg, #4a00e0 0%, #8e2de2 100%)',
              padding: '30px 20px',
              textAlign: 'center',
            }}
          >
            <Typography variant="h4" sx={{ color: 'white', fontWeight: 'bold', marginBottom: '10px' }}>
              Employee Login
            </Typography>
            <Typography variant="body1" sx={{ color: 'rgba(255, 255, 255, 0.8)' }}>
              Access your feedback portal
            </Typography>
          </Box>

          <CardContent sx={{ padding: '30px' }}>
            <Box
              component="form"
              onSubmit={(e) => {
                e.preventDefault();
                handleLoginClick();
              }}
              sx={{ '& > :not(style)': { mb: 3 } }}
            >
              <TextField
                fullWidth
                label="Email"
                variant="outlined"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter your email"
                sx={{
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '10px',
                    '&:hover fieldset': {
                      borderColor: '#8e2de2',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#4a00e0',
                    },
                  },
                }}
              />

              <TextField
                fullWidth
                label="Password"
                variant="outlined"
                type={showPassword ? 'text' : 'password'}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Enter your password"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Box
                        component="button"
                        type="button"
                        onClick={togglePasswordVisibility}
                        sx={{
                          background: 'none',
                          border: 'none',
                          cursor: 'pointer',
                          color: '#666',
                          padding: 0,
                        }}
                      >
                        {showPassword ? <FaEyeSlash /> : <FaEye />}
                      </Box>
                    </InputAdornment>
                  ),
                }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '10px',
                    '&:hover fieldset': {
                      borderColor: '#8e2de2',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#4a00e0',
                    },
                  },
                }}
              />

              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{
                  background: 'linear-gradient(135deg, #4a00e0 0%, #8e2de2 100%)',
                  color: 'white',
                  padding: '12px',
                  borderRadius: '10px',
                  fontWeight: 'bold',
                  textTransform: 'none',
                  fontSize: '16px',
                  '&:hover': {
                    background: 'linear-gradient(135deg, #3a00b0 0%, #7e1dd2 100%)',
                    boxShadow: '0 8px 15px rgba(142, 45, 226, 0.3)',
                  },
                }}
              >
                Login
              </Button>
            </Box>
          </CardContent>
        </Card>
      ) : (
        <>
          <Box
            sx={{
              position: 'absolute',
              top: '20px',
              right: '20px',
              zIndex: 10,
            }}
          >
            <Button
              variant="contained"
              onClick={handleLogout}
              startIcon={<FaSignOutAlt />}
              sx={{
                background: 'rgba(255, 255, 255, 0.9)',
                color: '#4a00e0',
                padding: '8px 16px',
                borderRadius: '10px',
                fontWeight: 'bold',
                textTransform: 'none',
                fontSize: '14px',
                boxShadow: '0 4px 10px rgba(0, 0, 0, 0.2)',
                '&:hover': {
                  background: 'rgba(255, 255, 255, 1)',
                  boxShadow: '0 6px 12px rgba(0, 0, 0, 0.3)',
                },
              }}
            >
              Logout
            </Button>
          </Box>

          <Box
            sx={{
              width: '100%',
              maxWidth: '900px',
              display: 'flex',
              flexDirection: 'column',
              gap: '20px',
            }}
          >
            {!showCategories ? (
              
              <Card
                sx={{
                  borderRadius: '20px',
                  boxShadow: '0 15px 35px rgba(0, 0, 0, 0.2)',
                  overflow: 'hidden',
                  background: 'rgba(255, 255, 255, 0.95)',
                  backdropFilter: 'blur(10px)',
                  padding: '30px',
                  textAlign: 'center',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: '20px',
                  }}
                >
                  <Box
                    sx={{
                      width: '100px',
                      height: '100px',
                      borderRadius: '50%',
                      background: 'linear-gradient(135deg, #4a00e0 0%, #8e2de2 100%)',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <FaCommentAlt size={40} color="white" />
                  </Box>

                  <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#333' }}>
                    Welcome to Your Feedback Portal!
                  </Typography>

                  <Typography variant="body1" sx={{ color: '#666', maxWidth: '600px', margin: '0 auto' }}>
                    Your opinions matter to us. This platform allows you to provide valuable feedback that helps us improve and grow together. Click the button below to get started.
                  </Typography>

                  <Button
                    variant="contained"
                    onClick={() => createFeedbackForTesting(employeeData?.result?.id, employeeData?.result?.organization_id)}
                    startIcon={<FaClipboardList />}
                    sx={{
                      background: 'linear-gradient(135deg, #4a00e0 0%, #8e2de2 100%)',
                      color: 'white',
                      padding: '12px 24px',
                      borderRadius: '10px',
                      fontWeight: 'bold',
                      textTransform: 'none',
                      fontSize: '16px',
                      '&:hover': {
                        background: 'linear-gradient(135deg, #3a00b0 0%, #7e1dd2 100%)',
                        boxShadow: '0 8px 15px rgba(142, 45, 226, 0.3)',
                      },
                    }}
                  >
                    View Feedback Categories
                  </Button>
                </Box>
              </Card>
            ) : (
             
              feedbackData && (
                <Card
                  sx={{
                    borderRadius: '20px',
                    boxShadow: '0 15px 35px rgba(0, 0, 0, 0.2)',
                    overflow: 'hidden',
                    background: 'rgba(255, 255, 255, 0.95)',
                    backdropFilter: 'blur(10px)',
                    padding: '30px',
                  }}
                >
                  <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#333', marginBottom: '20px' }}>
                    Feedback Categories
                  </Typography>

                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
                    {feedbackData.categories.map((category, index) => (
                      <Paper
                        key={category.id || index}
                        elevation={3}
                        sx={{
                          padding: '15px',
                          borderRadius: '12px',
                          background: 'linear-gradient(to right, #f5f7fa, #c3cfe2)',
                          display: 'flex',
                          alignItems: 'center',
                          gap: '15px',
                        }}
                      >
                        <Box
                          sx={{
                            width: '40px',
                            height: '40px',
                            borderRadius: '50%',
                            background: 'linear-gradient(135deg, #4a00e0 0%, #8e2de2 100%)',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexShrink: 0,
                          }}
                        >
                          <Typography variant="body1" sx={{ color: 'white', fontWeight: 'bold' }}>
                            {index + 1}
                          </Typography>
                        </Box>

                        <Typography variant="body1" sx={{ fontWeight: 'medium', color: '#333' }}>
                          {category.category}
                        </Typography>
                      </Paper>
                    ))}
                  </Box>

                  <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '30px' }}>
                    <Button
                      variant="contained"
                      onClick={handleViewFeedback}
                      sx={{
                        background: 'linear-gradient(135deg, #4a00e0 0%, #8e2de2 100%)',
                        color: 'white',
                        padding: '12px 24px',
                        borderRadius: '10px',
                        fontWeight: 'bold',
                        textTransform: 'none',
                        fontSize: '16px',
                        '&:hover': {
                          background: 'linear-gradient(135deg, #3a00b0 0%, #7e1dd2 100%)',
                          boxShadow: '0 8px 15px rgba(142, 45, 226, 0.3)',
                        },
                      }}
                    >
                      Provide Feedback
                    </Button>
                  </Box>
                </Card>
              )
            )}
          </Box>
        </>
      )}
    </Box>
  );
};

export default EmployeeLogin;