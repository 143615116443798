import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import "./Send_Feedback_Request.css";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FidgetSpinner } from 'react-loader-spinner';

const FeedbackRequestForm = ({ onClose = () => {}, roles = [] }) => {
    const requestFrequencyRef = useRef(null);
    const sendRequestRef = useRef(null);
    const [isPopupVisible, setIsPopupVisible] = useState(true);
    const [requestFrequency, setRequestFrequency] = useState("");
    const [sendRequestTo, setSendRequestTo] = useState("all");
    const [categories, setCategories] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [isOtherSelected, setIsOtherSelected] = useState(false);
    const [otherText, setOtherText] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [showSuccessPopup, setShowSuccessPopup] = useState(false); 

    useEffect(() => {
        fetch(`${process.env.REACT_APP_BACKEND_URL}/masterdata/getAllMasterDropdowns?type=category`)
            .then(response => response.json())
            .then(data => {
                if (data.statusCode === 200) {
                    setCategories([{ id: "all", name: "All" }, ...data.result]);
                } else {
                    console.error("Failed to fetch categories");
                }
            })
            .catch(error => {
                console.error("Error fetching categories:", error);
            });
    }, []);

    const handleArrowClick = (ref) => {
        ref.current.focus();
        ref.current.click();
    };

    const handleClose = () => {
        setIsPopupVisible(false);
        onClose();
    };

    const handleSendRequest = () => {
        const storedManagerId = localStorage.getItem("manager_id");
        const storedOrganizationId = localStorage.getItem("organization_id");

        if (!storedManagerId || !storedOrganizationId) {
            toast.error("Missing required data to send feedback request.");
            return;
        }

        setIsLoading(true);

        const otherPointsArray = otherText
            .split(/[\n,]+/)
            .map(point => point.trim())
            .filter(point => point !== '');

        const requestData = {
            manager_id: storedManagerId,
            organization_id: storedOrganizationId,
            roles: Array.isArray(roles) && roles.length > 0 ? roles : [],
            categories: isOtherSelected
                ? otherPointsArray
                : selectedCategories.includes("All")
                ? categories.filter(c => c.name !== "All").map(c => c.name)
                : selectedCategories,
            request_frequency: requestFrequency,
            request_to: sendRequestTo,
            other_feedback: isOtherSelected ? otherPointsArray : null 
        };

        fetch(`${process.env.REACT_APP_BACKEND_URL}/feedback/createFeedback`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(requestData)
        })
            .then(response => response.json())
            .then(data => {
                setIsLoading(false);
                if (data.statusCode === 200) {
                    setShowSuccessPopup(true); 
                } else {
                    toast.error("Failed to send feedback request.");
                }
            })
            .catch(error => {
                setIsLoading(false);
                toast.error("There was an error sending the feedback request.");
                console.error("There was an error sending the feedback request:", error);
            });
    };

    const handleCategoryChange = (event) => {
        const {
            target: { value, checked },
        } = event;

        if (value === "Other") {
            setIsOtherSelected(checked);
            if (checked) {
                setSelectedCategories([]); 
            }
        } else {
            if (checked) {
                setSelectedCategories(prevCategories => [...prevCategories, value]);
                setIsOtherSelected(false); 
            } else {
                setSelectedCategories(prevCategories =>
                    prevCategories.filter(category => category !== value)
                );
            }
        }
    };

    const handleOtherTextChange = (event) => {
        setOtherText(event.target.value);
    };

   
    const handleSuccessClose = () => {
        setShowSuccessPopup(false);
    };

    return (
        isPopupVisible && (
            <div className="feedBack-container">
                {isLoading && (
                    <div className="modal-overlay">
                        <div className="modal-content">
                            <FidgetSpinner
                                visible={true}
                                height="80"
                                width="80"
                                ariaLabel="fidget-spinner-loading"
                                backgroundColor="blue"
                            />
                        </div>
                    </div>
                )}
                
               
                {showSuccessPopup && (
    <div className="success-popup-overlay">
        <div className="success-popup-content">
            <img src="/Images/Success.png" alt="Success" className="success-popup-image" />
            <p className="success-popup-text">Your feedback request has been sent.</p>
            <button className="success-popup-button" onClick={handleSuccessClose}>
                Done
            </button>
        </div>
    </div>
)}


                <div className="feedback-form-container">
                  <div className="feedback-header">
                      <div className="feedback-title">
                          <p>Feedback request form</p>
                          <button onClick={handleClose}>
                              <img 
                                  src="./Images/close button.png" 
                                  className="feedback-request-close-button" 
                                  alt="Close"
                              />
                          </button>
                      </div>
                  </div>

                  <ToastContainer position="top-right" autoClose={5000} />
                   
                  <div className="feedbackForm-content">
                      <div className="feedback-column">
                          <div className="feedback-formGroup">
                              <label className="feedback-formName">Feedback request frequency</label>
                              <div className="custom-select-container">
                                  <select
                                      ref={requestFrequencyRef}
                                      className="inputBox-for-request-frequency"
                                      value={requestFrequency}
                                      onChange={(e) => setRequestFrequency(e.target.value)}
                                  >
                                      <option value="" disabled>Select frequency</option>
                                      <option value="once">Once</option>
                                  </select>
                                  <img src="./Images/icons8-sort-down-100 1.png" alt="arrow" className="down-arrow" onClick={() => handleArrowClick(requestFrequencyRef)} />
                              </div>
                          </div>

                          <div className="feedback-formGroup">
                              <label className="feedback-formName">Send request to</label>
                              <div className="custom-select-container">
                                  <select
                                      ref={sendRequestRef}
                                      className="inputBox-for-send-request"
                                      value={sendRequestTo}
                                      onChange={(e) => setSendRequestTo(e.target.value)}
                                  >
                                      <option value="all">All</option>
                                  </select>
                                  <img src="./Images/icons8-sort-down-100 1.png" alt="arrow" className="down-arrow" onClick={() => handleArrowClick(sendRequestRef)} />
                              </div>
                          </div>
                      </div>

                      <div className="feedback-column">
                          <div className="feedback-formGroup">
                              <label className="feedback-formName">Type of feedback request</label>
                              <div className="feedback-categories">
                                  <label>
                                      Work Environment
                                      <input type="checkbox" value="Work Environment" onChange={handleCategoryChange} />
                                  </label>
                                  <label>
                                      Management
                                      <input type="checkbox" value="Management" onChange={handleCategoryChange} />
                                  </label>
                                  <label>
                                      Team Dynamics
                                      <input type="checkbox" value="Team Dynamics" onChange={handleCategoryChange} />
                                  </label>
                                  <label>
                                      Tools And Resources
                                      <input type="checkbox" value="Tools And Resources" onChange={handleCategoryChange} />
                                  </label>
                                  <label>
                                      Suggestions For Improvement
                                      <input type="checkbox" value="Suggestions For Improvement" onChange={handleCategoryChange} />
                                  </label>
                                  <label>
                                      Other
                                      <input type="checkbox" value="Other" onChange={handleCategoryChange} />
                                  </label>
                              </div>
                          </div>
                      </div>

                      <div className="feedback-column">
                          {isOtherSelected && (
                              <div className="feedback-formGroup">
                                  <label className="feedback-formName">Other Feedback</label>
                                  <div className="feedback-other-categories">
                                      <textarea
                                          className="other-feedback-textarea"
                                          value={otherText}
                                          onChange={handleOtherTextChange}
                                          placeholder="Enter other feedback..."
                                      />
                                  </div>
                              </div>
                          )}
                      </div>
                  </div>

                  <div className="feedback-send-button">
                      <button className="feedback-send-button-text" onClick={handleSendRequest}>Send Request</button>
                  </div>
                </div>
            </div>
        )
    );
};

FeedbackRequestForm.propTypes = {
    onClose: PropTypes.func.isRequired,
    roles: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default FeedbackRequestForm;
