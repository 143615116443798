import React, { useState, useEffect, useRef } from 'react';
import './Auditor_FeedBack_Check.css';
import { Select, MenuItem, Pagination } from '@mui/material';  // Import Pagination from MUI
import { FidgetSpinner } from 'react-loader-spinner';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AuditorFeedBackCheck = () => {
    const [progress, setProgress] = useState(0);
    const [audioSrc, setAudioSrc] = useState(null);
    const [feedbackOptions, setFeedbackOptions] = useState([]);
    const [selectedFeedback, setSelectedFeedback] = useState('');
    const [employeeFeedbacks, setEmployeeFeedbacks] = useState([]);
    const [transcription, setTranscription] = useState('');
    const [summary, setSummary] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const audioRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const employeeName = `${localStorage.getItem('first_name')} ${localStorage.getItem('last_name')}`;
    const employeeId = localStorage.getItem('employee_id');
    const employeeRole = localStorage.getItem('role');
   
    const [updatedDate, setUpdatedDate] = useState('');
    const [updatedTime, setUpdatedTime] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(3);

    const [selectedFeedbackInfo, setSelectedFeedbackInfo] = useState({ employeeId: null, feedbackId: null });
    const [editableTranscription, setEditableTranscription] = useState('');
    const [isEditing, setIsEditing] = useState(false);
    const transcriptionRef = useRef(null);
    const [feedbackId, setFeedbackId] = useState(null);
    const [isSaving, setIsSaving] = useState(false);

    const formatDateAndTime = (dateString) => {
        const date = new Date(dateString);
        const formattedDate = date.toLocaleDateString('en-GB');
        const formattedTime = date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
        return { formattedDate, formattedTime };
    };

    useEffect(() => {
        const fetchEmployeeFeedbacks = async () => {
            setIsLoading(true);
            try {
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/employeeRoutes/getAllEmployeeForAuditor`);
                const data = await response.json();
                setEmployeeFeedbacks(data.result || []);
            } catch (error) {
                console.error("Error fetching employee feedbacks:", error);
                setEmployeeFeedbacks([]);
            } finally {
                setIsLoading(false);
            }
        };

        fetchEmployeeFeedbacks();
    }, []);

    const handleViewFeedback = async (employeeId, feedbackId) => {
        if (audioRef.current) {
            audioRef.current.pause();
            audioRef.current.currentTime = 0;
            setProgress(0);
            setIsPlaying(false);
            audioRef.current.ontimeupdate = null;
        }

        setSelectedFeedbackInfo({ employeeId, feedbackId });
        setIsLoading(true);

        setTimeout(async () => {
            try {
                setFeedbackOptions([]);
                setSelectedFeedback('');
                setAudioSrc(null);
                setTranscription('');
                setSummary('');

                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/employeeRoutes/getCategoryForAuditor?employee_id=${employeeId}&feedbackId=${feedbackId}`);
                const data = await response.json();

                const formattedOptions = data.result ? data.result.map(item => ({
                    value: item.id,
                    label: item.category,
                    audioUrl: item.voice_record,
                    transcription: item.feedback,
                    summary: item.summary,
                    updatedAt: item.updatedAt,
                })) : [];

                setFeedbackOptions(formattedOptions);

                if (formattedOptions.length > 0) {
                    const firstOption = formattedOptions[0];
                    setSelectedFeedback(firstOption.value);
                    setAudioSrc(firstOption.audioUrl);
                    setTranscription(firstOption.transcription);
                    setEditableTranscription(firstOption.transcription);
                    setSummary(firstOption.summary);
                    setFeedbackId(firstOption.value);

                    const { formattedDate, formattedTime } = formatDateAndTime(firstOption.updatedAt);
                    setUpdatedDate(formattedDate);
                    setUpdatedTime(formattedTime);
                } else {
                    console.log('No feedback options available');
                }
            } catch (error) {
                console.error('Error fetching feedback keypoints:', error);
                setFeedbackOptions([]);
            } finally {
                setIsLoading(false);
            }
        }, 500);
    };

    const handleSaveFeedback = async () => {
        setIsSaving(true);
        try {
            const payload = {
                id: feedbackId,
                feedback: editableTranscription
            };

            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/feedback/updateFeedBackByAuditorByCategory`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload),
            });

            const data = await response.json();

            setTimeout(() => {
                if (data.statusCode === 200) {
                    setTranscription(editableTranscription);
                    setSummary(data.result?.summary || summary);
                    toast.success('Feedback updated successfully');
                } else {
                    console.error('Error updating feedback:', data.message);
                    toast.error(`Error: ${data.message}`);
                }
                setIsSaving(false);
            }, 500);
        } catch (error) {
            console.error('Error updating feedback:', error);
            toast.error(`Error: ${error.message}`);
            setIsSaving(false);
        }
    };

    const handleToggleEditSave = () => {
        if (isEditing) {
            handleSaveFeedback();
        }
        setIsEditing(!isEditing);
    };

    const handleInput = (e) => {
        const selection = window.getSelection();
        const range = selection.getRangeAt(0);
        const startOffset = range.startOffset;

        setEditableTranscription(e.currentTarget.textContent);

        setTimeout(() => {
            const newSelection = window.getSelection();
            const newRange = document.createRange();

            if (transcriptionRef.current && transcriptionRef.current.firstChild) {
                newRange.setStart(transcriptionRef.current.firstChild, startOffset);
                newRange.collapse(true);

                newSelection.removeAllRanges();
                newSelection.addRange(newRange);
            }
        }, 0);
    };

    const copyTranscription = () => {
        const transcriptionText = transcriptionRef.current?.textContent || '';
        if (transcriptionText.trim()) {
            navigator.clipboard.writeText(transcriptionText).then(() => {
                toast.success('Transcription copied to clipboard!');
            }).catch((error) => {
                toast.error('Failed to copy transcription.');
                console.error('Error copying text: ', error);
            });
        }
    };

    const handleFeedbackChange = (event) => {
        const selectedValue = event.target.value;
        const selectedOption = feedbackOptions.find(option => option.value === selectedValue);
        if (selectedOption) {
            setSelectedFeedback(selectedValue);
            setAudioSrc(selectedOption.audioUrl);
            setTranscription(selectedOption.transcription);
            setEditableTranscription(selectedOption.transcription);
            setSummary(selectedOption.summary);
            const { formattedDate, formattedTime } = formatDateAndTime(selectedOption.updatedAt);
            setUpdatedDate(formattedDate);
            setUpdatedTime(formattedTime);
            
           
            if (audioRef.current) {
                audioRef.current.pause();
                audioRef.current.currentTime = 0;
                setProgress(0);
                setIsPlaying(false);
                audioRef.current.ontimeupdate = null; 
            }
        }
    };

    const handleTogglePlayPause = () => {
        if (audioRef.current) {
            if (isPlaying) {
                audioRef.current.pause();
            } else {
                if (audioRef.current.readyState >= 2) {
                    audioRef.current.play();
    
                    audioRef.current.ontimeupdate = () => {
                       
                        if (audioRef.current && audioRef.current.duration) {
                            const duration = audioRef.current.duration;
                            const currentTime = audioRef.current.currentTime;
                            setProgress((currentTime / duration) * 100);
                        }
                    };
                }
            }
            setIsPlaying(!isPlaying);
        }
    };

    const handleStop = () => {
        if (audioRef.current) {
            audioRef.current.pause();
            audioRef.current.currentTime = 0;
            setProgress(0);
            setIsPlaying(false);
        }
    };

    const sortedEmployeeFeedbacks = [...employeeFeedbacks].sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentFeedbacks = sortedEmployeeFeedbacks.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(employeeFeedbacks.length / itemsPerPage);

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    return (
        <div>
            <ToastContainer />
            {isLoading && (
                <div className="modal-overlay">
                    <div className="modal-content">
                        <FidgetSpinner visible={true} height="80" width="80" ariaLabel="fidget-spinner-loading" backgroundColor="blue" />
                    </div>
                </div>
            )}

            {!isLoading && (
                <div className='Auditor_feedback_check_Main_container'>
                    <div className='Auditor_feedback_check_Left_container'>
                        <div className='Auditor_Feedback_List'>
                            <p className='Recent-feedback-updates'>Recent feedback updates</p>
                        </div>

                        {currentFeedbacks.map(employee => {
                            const { formattedDate, formattedTime } = formatDateAndTime(employee.updatedAt);
                            const isSelected = selectedFeedbackInfo.employeeId === employee.employee_id && selectedFeedbackInfo.feedbackId === employee.id;

                            return (
                                <div key={employee.id} className={`Auditor_Recieved_employee_feedbacks_container ${isSelected ? 'selected-feedback' : ''}`}>
                                    <div className='Auditor_Feedback_Details'>
                                        <div className='Auditor_Details_Container'>
                                            <div className='Auditor_Details'>
                                                <p className='Auditor_Name'>Employee ID: {employee.employee_id}</p>
                                            </div>
                                            <div className='Auditor_feedbacks_recieved_date_and_time'>
                                                {formattedDate} - {formattedTime}
                                            </div>
                                        </div>
                                        <p className='Voice-feedback-received-successfully'>
                                            {employee.is_employee_feeded ? 'Voice feedback received successfully' : 'Feedback not received yet'}
                                        </p>
                                        <button className='View-feedback-button' onClick={() => handleViewFeedback(employee.employee_id, employee.id)}>
                                            View Feedback
                                        </button>
                                    </div>
                                    <div className='Auditor_Image_Container'>
                                        <img src='./Images/employees.png' className='Auditor_Profile' alt='Auditor' />
                                    </div>
                                </div>
                            );
                        })}

                        <div className="pagination-container">
                        <Pagination
    count={totalPages}
    page={currentPage}
    onChange={handlePageChange}
    siblingCount={1}    
    boundaryCount={0}  
    variant="outlined"
    shape="rounded"
    size="large"
    color="primary"
    showFirstButton={false}
    showLastButton={false}
/>



                        </div>
                    </div>

                    <div className='Auditor_feedback_check_Right_container'>
                        <div className='Auditor-details-and-feedbacks'>
                            <div className='Auditor-sub-details-and-feedbacks'>
                                <p className="Auditor-Employee-feedback">Employee feedback</p>
                                {updatedDate && updatedTime && (
                                    <div className='Auditor-feedback-recieved-time-and-date'>
                                        <p>{updatedDate}</p>
                                        <p>-</p>
                                        <p>{updatedTime}</p>
                                    </div>
                                )}
                            </div>

                            <div className='Auditor-name-plate-details'>
                                <p className="Auditor-name">{employeeName}</p>
                                <p className='Auditor-Role'>Role : {employeeRole}</p>
                                <p className="Auditor-Emp-id">Emp Id: {employeeId}</p>
                            </div>
                        </div>

                        {feedbackOptions.length > 0 && (
                            <>
                                <div>
                                    <div className="Recieved-feedbacks-from-employees-Auditor">
                                        <Select
                                            value={selectedFeedback}
                                            onChange={handleFeedbackChange}
                                            variant="outlined"
                                            className="small-dropdown"
                                        >
                                            {feedbackOptions.map(option => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </div>

                                    {audioSrc ? (
  <div className='Auditor-Feedbacks-playback-container'>
    <p className='Auditor-playback-audio'>Playback</p>
    <div className='play-back-icons'>
      <img
        src={isPlaying ? './Images/pause.png' : './Images/resume.png'}
        className='Auditor-feedback-play-button'
        alt={isPlaying ? 'pause' : 'play'}
        onClick={handleTogglePlayPause}
      />
      <img src='./Images/stop.png' className='Auditor-feedback-stop-button' alt='stop' onClick={handleStop} />
      <div className='Auditor-playback-progress'>
        <div className='Auditor-playback-progress-bar' style={{ width: `${progress}%` }}></div>
      </div>
    </div>
  </div>
) : null}

                                </div>

                                <div className='Auditor-transcription-container'>
                                    <div className='Auditor-transcription-icons'>
                                        <img
                                            src={isEditing ? './Images/Save.png' : './Images/Edit-Text.png'}
                                            alt={isEditing ? 'Save' : 'Edit'}
                                            className='Auditor-icon'
                                            onClick={handleToggleEditSave}
                                        />
                                        <img src='./Images/Copy-Text.png' alt='Copy Text' className='Auditor-icon' onClick={copyTranscription} />
                                    </div>

                                    <p className='transcription'>Transcription</p>

                                    <Scrollbars
                                        autoHide
                                        style={{ height: '150px' }}
                                        renderThumbVertical={({ style, ...props }) => (
                                            <div {...props} style={{ ...style, backgroundColor: '#007bff', borderRadius: '10px', marginLeft: '20px' }} />
                                        )}
                                    >
                                        <p
                                            ref={transcriptionRef}
                                            className='transcription-data'
                                            contentEditable={isEditing}
                                            suppressContentEditableWarning={true}
                                            onInput={handleInput}
                                        >
                                            {editableTranscription || 'No transcription available'}
                                        </p>
                                    </Scrollbars>
                                </div>

                                <div className='Auditor-Summary-container'>
                                    <p className='summary'>Summary</p>
                                    <Scrollbars
                                        autoHide
                                        style={{ height: '150px' }}
                                        renderThumbVertical={({ style, ...props }) => (
                                            <div {...props} style={{ ...style, backgroundColor: '#007bff', borderRadius: '10px', marginLeft: '20px' }} />
                                        )}
                                    >
                                        {summary && summary.split(/(?=[*•])/).filter(point => point.trim() !== '').map((point, index) => (
                                            <p key={index}>
                                                {point.trim().endsWith(':') ? point.trim() : point.trim().startsWith('*') || point.trim().startsWith('•') ? point.trim() : `* ${point.trim()}`}
                                            </p>
                                        ))}
                                    </Scrollbars>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            )}

            {audioSrc && <audio ref={audioRef} src={audioSrc} onError={() => console.error('Audio source failed to load')} />}
        </div>
    );
};

export default AuditorFeedBackCheck;
