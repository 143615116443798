import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./Employee-table.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleDateString(); 
};

const AddEmployeeTable = ({ searchTerm }) => {
    const [data, setData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(6);
    const [selectAll, setSelectAll] = useState(false);
    const navigate = useNavigate(); 
    const managerid = localStorage.getItem('manager_id');

    useEffect(() => {
        const fetchData = async () => {
            if (!managerid) {
                console.error('Manager ID is not found in local storage');
                return;
            }
    
            try {
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/employeeRoutes/employeesByManagerId?manager_id=${managerid}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    }
                });
    
                if (response.ok) {
                    const result = await response.json();
    
                    if (Array.isArray(result.result)) {
                        const employeeData = result.result.reduce((acc, curr) => {
                            if (Array.isArray(curr)) {
                                return [...acc, ...curr];
                            } else if (curr && typeof curr === 'object') {
                                return [...acc, curr];
                            }
                            return acc;
                        }, []);
    
                        setData(employeeData.map((item) => ({
                            id: item.id,
                            name: `${item.first_name} ${item.last_name}`,
                            email: item.email,
                            gender: item.gender,
                            role: item.role,
                            dob: formatDate(item.dob),
                            phno: item.mobile_number,
                          imageUrl: item.gender.toLowerCase() === 'female' ? './Images/female employees.png' : './Images/male employees.png',
                            isSelected: false
                        })));
                    } else {
                        console.error('API response does not have the expected structure:', result);
                    }
                } else {
                    console.error('Failed to fetch employee data:', response.statusText);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
    
        fetchData();
    }, [managerid]);


    const filteredEmployeeData = data.filter((admin) =>
        admin.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        admin.role?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        (admin.empId ? admin.empId.toString().includes(searchTerm) : false) ||
        admin.email?.toLowerCase().includes(searchTerm.toLowerCase())
    );
    

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 768) {
                setItemsPerPage(2);
            } else {
                setItemsPerPage(6); 
            }
        };

        window.addEventListener("resize", handleResize);
        handleResize(); 

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const handleSelectAll = () => {
        setSelectAll(!selectAll);
        setData(data.map(item => ({ ...item, isSelected: !selectAll })));
    };

    const handleSelectOne = (id) => {
        const updatedData = data.map(item => {
            if (item.id === id) {
                return { ...item, isSelected: !item.isSelected };
            }
            return item;
        });
        setData(updatedData);
        setSelectAll(updatedData.every(item => item.isSelected));
    };

    const handleDeleteSelected = async () => {
        const selectedIds = data.filter(item => item.isSelected).map(item => item.id);

        try {
            for (let id of selectedIds) {
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/employeeRoutes/deleteEmployee?id=${id}`, {
                    method: 'DELETE'
                });

                const result = await response.json();
                if (response.ok && result.statusCode === 200) {
                    setData(prevData => prevData.filter(item => item.id !== id));
                } else {
                    toast.error(`Failed to delete employee with id: ${id}`);
                }
            }
        } catch (error) {
            console.error('Error deleting data:', error);
            toast.error('Error deleting selected employees.');
        }
    };

    const handleEditClick = () => {
        const selectedEmployees = data.filter(item => item.isSelected);
        if (selectedEmployees.length === 1) {
            const selectedEmployeeId = selectedEmployees[0].id;
            navigate(`/update-employee/${selectedEmployeeId}`); 
            toast.error("Please select exactly one employee to edit.");
        }
    };

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredEmployeeData.slice(indexOfFirstItem, indexOfLastItem);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    return (
        <div>
            <ToastContainer />
            <div className="employeeList-additions-edit-container">
                <div className="employeeList-addtions-edit-checkbox">
                    <input
                        type="checkbox"
                        className="employeeList-edit-checkbox"
                        checked={selectAll}
                        onChange={handleSelectAll}
                    />
                </div>
                <div className="employeeList-addtions-edit-delete-options">
                    <img
                        src="./Images/edit-icon.png"
                        className="employeeList-edit-icon"
                        alt="EmployeeList Edit Icon"
                        onClick={handleEditClick} 
                    />
                    <img
                        src="./Images/delete_icon.png"
                        className="employeeList-edit-icon"
                        alt="EmployeeList delete Icon"
                        onClick={handleDeleteSelected}
                    />
                </div>
            </div>
            <hr className="employeeList-content-divider" />
            <div className="add-employee-parent-container">
                {currentItems.map((employee) => (
                    <div className="add-employee-table-details-container" key={employee.id}>
                        <div className="triangle-background">
                            <input
                                type="checkbox"
                                className="employee-table-checkbox-input"
                                checked={employee.isSelected}
                                onChange={() => handleSelectOne(employee.id)}
                            />
                            <div className="add-employee-table-details">
                                <div className="add-employee-table-details-name-container">
                                    <div className="add-employee-table-details-name-container-left">
                                        <div className="add-employee-table-details-name-container-with-image-and-name">
                                        <p className="add-employee-table-details-label">Name</p>
                                        <img src={employee.imageUrl} className="add-employee-table-details-img-value" alt="employee-pic"></img>
                                        </div>
                                       
                                        <p className="add-employee-table-details-name-value">{employee.name}</p>
                                        <p className="add-employee-table-details-email-value">{employee.email}</p>
                                        <p className="add-employee-table-details-id-value"><span className="add-employee-table-details-emp-id">Emp id : </span>{employee.id}</p>
                                    </div>
                                    {/* <div className="add-employee-table-details-name-container-right">
                                        <img src={employee.imageUrl} className="add-employee-table-details-img-value" alt="employee-pic"></img>
                                    </div> */}
                                </div>
                                <div className="add-employee-table-details-name-container-bottom">
                                    <div className="add-employee-table-details-name-container-bottom-left">
                                        <p className="add-employee-table-details-label">Gender</p>
                                        <p className="add-employee-table-details-value">{employee.gender}</p>
                                        <p className="add-employee-table-details-label">Role</p>
                                        <p className="add-employee-table-details-value">{employee.role}</p>
                                    </div>
                                    <div className="add-employee-table-details-name-container-bottom-right">
                                        <p className="add-employee-table-details-label">D.O.B</p>
                                        <p className="add-employee-table-details-value">{employee.dob}</p>
                                        <p className="add-employee-table-details-label">Mobile Number</p>
                                        <p className="add-employee-table-details-value">{employee.phno}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            {filteredEmployeeData.length > itemsPerPage && (
                <Pagination
                    itemsPerPage={itemsPerPage}
                    totalItems={data.length}
                    paginate={paginate}
                    currentPage={currentPage}
                />
            )}
        </div>
    );
};

const Pagination = ({ itemsPerPage, totalItems, paginate, currentPage }) => {
    const pageNumbers = [];
    const totalPages = Math.ceil(totalItems / itemsPerPage);

    for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
    }

    const maxPageNumbersToShow = 5;
    let startPage = Math.max(1, currentPage - Math.floor(maxPageNumbersToShow / 2));
    let endPage = startPage + maxPageNumbersToShow - 1;

    if (endPage > pageNumbers.length) {
        endPage = pageNumbers.length;
        startPage = Math.max(1, endPage - maxPageNumbersToShow + 1);
    }

    return (
        <nav>
            <ul className="pagination">
                {startPage > 1 && (
                    <>
                        <li className="page-item">
                            <button onClick={() => paginate(1)} className="page-link">1</button>
                        </li>
                        {startPage > 2 && <li className="page-item disabled"><span className="page-link">...</span></li>}
                    </>
                )}
                {pageNumbers.slice(startPage - 1, endPage).map(number => (
                    <li key={number} className={`page-item ${currentPage === number ? 'active' : ''}`}>
                        <button onClick={() => paginate(number)} className="page-link">
                            {number}
                        </button>
                    </li>
                ))}
                {endPage < pageNumbers.length && (
                    <>
                        {endPage < pageNumbers.length - 1 && <li className="page-item disabled"><span className="page-link">...</span></li>}
                        <li className="page-item">
                            <button onClick={() => paginate(pageNumbers.length)} className="page-link">{pageNumbers.length}</button>
                        </li>
                    </>
                )}
            </ul>
        </nav>
    );
};

export default AddEmployeeTable;
