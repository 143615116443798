// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Feedback_Success_overlay__f5Yq9 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.Feedback_Success_popup__2wncm {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  text-align: center;
  max-width: 90%;
}

.Feedback_Success_successImage__IyKHH {
  width: 500px;
  height: 300px;
 
}

.Feedback_Success_messageContainer__h7sOj {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.Feedback_Success_smallSuccessImage__eRLQ5 {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.Feedback_Success_successMessage__5v\\+4t {
  font-size: 16px;
  font-weight: 400;
}

.Feedback_Success_doneButton__1xSDB {
  padding: 10px 20px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.Feedback_Success_doneButton__1xSDB:hover {
  background-color: #45a049;
}
`, "",{"version":3,"sources":["webpack://./src/Components/Feedback_Success/Feedback_Success.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;EACZ,oCAAoC;EACpC,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;AACf;;AAEA;EACE,sBAAsB;EACtB,aAAa;EACb,kBAAkB;EAClB,2CAA2C;EAC3C,kBAAkB;EAClB,cAAc;AAChB;;AAEA;EACE,YAAY;EACZ,aAAa;;AAEf;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,yBAAyB;EACzB,YAAY;EACZ,YAAY;EACZ,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".overlay {\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background-color: rgba(0, 0, 0, 0.7);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  z-index: 1000;\n}\n\n.popup {\n  background-color: #fff;\n  padding: 20px;\n  border-radius: 8px;\n  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);\n  text-align: center;\n  max-width: 90%;\n}\n\n.successImage {\n  width: 500px;\n  height: 300px;\n \n}\n\n.messageContainer {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  margin-bottom: 20px;\n}\n\n.smallSuccessImage {\n  width: 20px;\n  height: 20px;\n  margin-right: 10px;\n}\n\n.successMessage {\n  font-size: 16px;\n  font-weight: 400;\n}\n\n.doneButton {\n  padding: 10px 20px;\n  background-color: #4CAF50;\n  color: white;\n  border: none;\n  border-radius: 5px;\n  cursor: pointer;\n}\n\n.doneButton:hover {\n  background-color: #45a049;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"overlay": `Feedback_Success_overlay__f5Yq9`,
	"popup": `Feedback_Success_popup__2wncm`,
	"successImage": `Feedback_Success_successImage__IyKHH`,
	"messageContainer": `Feedback_Success_messageContainer__h7sOj`,
	"smallSuccessImage": `Feedback_Success_smallSuccessImage__eRLQ5`,
	"successMessage": `Feedback_Success_successMessage__5v+4t`,
	"doneButton": `Feedback_Success_doneButton__1xSDB`
};
export default ___CSS_LOADER_EXPORT___;
