import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import LogOut from '../Components/LogOut/LogOut';
import './Header.css';
import Switch from '@mui/material/Switch';

const Header = ({ isLoggedIn, onLogout, onSwitchChange = () => {}, switchState }) => {
  const [organizationName, setOrganizationName] = useState('');
  const location = useLocation();
  const navigate = useNavigate(); 

  const hideHeaderRoutes = [
    '/create-company',
    '/company-name',
    '/create-password',
    '/super-admin-login',
    '/user-login',
    '/reset-password/masterData/forgetPasswordEmail',
    '/update-managers',
    '/update-employee',
    '/employee-login'
  ];

  const noLogoutRoutes = [
    '/employee-feedback',
    '/employee-feedback-testing',
    '/employee-feedback/employee'
  ];

  const shouldHideHeader = hideHeaderRoutes.some(route => location.pathname.startsWith(route));
  const shouldHideLogout = noLogoutRoutes.includes(location.pathname);

  useEffect(() => {
    const fetchOrganizationName = async () => {
      const organizationId = localStorage.getItem('organization_id');
      if (!organizationId) {
        console.error('No organization ID found');
        return;
      }

      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/organizationRoutes/getOrganizationById?organization_id=${organizationId}`
        );
        if (response.ok) {
          const data = await response.json();
          setOrganizationName(data.result.organization);
        } else {
          console.error('Failed to fetch organization name');
        }
      } catch (error) {
        console.error('Error fetching organization name:', error);
      }
    };

    if (!shouldHideHeader) {
      fetchOrganizationName();
    }
  }, [location.pathname, shouldHideHeader]);

  const handleSwitchChange = (event) => {
    const newSwitchState = event.target.checked;
    onSwitchChange(newSwitchState); 
  
    if (newSwitchState) {
      navigate('/manager-employees');
    } else {
      navigate('/manager-dashboard');
    }
  };
  
  if (shouldHideHeader) {
    return null;
  }

  return (
    <div className="header">
      {organizationName && <h1 className="companyTitle">{organizationName}</h1>}

      {isLoggedIn && (
        <div className="header-actions">
          {(location.pathname === '/manager-dashboard' || location.pathname === '/manager-employees') && (
            <div className="manager-toggle-switch">
              {switchState && <span>Feedback</span>}
              <Switch
                checked={switchState}
                onChange={handleSwitchChange}
                sx={{
                  '& .MuiSwitch-switchBase.Mui-checked': {
                    color: '#1976d2',
                  },
                  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                    backgroundColor: '#1976d2',
                  },
                  '& .MuiSwitch-switchBase': {
                    color: '#4caf50',
                  },
                  '& .MuiSwitch-track': {
                    backgroundColor: '#4caf50',
                  },
                }}
              />
              {!switchState && <span>Employees</span>}
            </div>
          )}

          {!shouldHideLogout && <LogOut onLogout={onLogout} />}
        </div>
      )}
    </div>
  );
};

export default Header;
