import React, { useState, useEffect } from 'react';
import Header from './Header/Header';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import OrganizationSignup from './Components/Organization_Signup/Organization_Signup';
import SuperAdminLoginForm from './Components/Super_Admin_Login_Form/Super_Admin_Login_Form';
import UpdateOrganization from './Components/Update_Organization/Update_Organization';
import SuperAdminDashboard from './Components/Super_Admin_Dashboard/Super_Admin_Dashboard';
import AddAdmin from './Components/Add_Admin/Add_Admin';
import LoginForm from './Components/Login/Login';
import CreatePassword from './Components/Create_Password/Create_Password';
import AdminDashboard from './Components/Admin_Dashboard/Admin_Dashboard';
import AddEmployee from './Components/Create_Employee/Create_Employee';
import EmployeeFeedback from './Components/Employees-Flow/Employees-Flow';
import AuditorFeedBackCheck from './Components/Auditor_FeedBack_Check/Auditor_FeedBack_Check';
import UpdateEmployee from './Components/Update_Employee/Update_Employee';  
import UpdateManagers from './Components/Update_Managers/Update_Managers';
import SuperAdminResetPassword from './Components/SuperAdminResetPassword/SuperAdminResetPassword';
import UserResetPassword from './Components/UserResetPassword/UserResetpassword';
import { Scrollbars } from 'react-custom-scrollbars-2';
import  Managerdashboard from './Components/manager-dashboard/manager-dashboard';
import './App.css';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(localStorage.getItem('isLoggedIn') === 'true');
  const [userRole, setUserRole] = useState(localStorage.getItem('userRole') || '');
  const organizationId = localStorage.getItem('organization_id'); 
  const [organizationName, setOrganizationName] = useState(localStorage.getItem('companyName') || '');

  useEffect(() => {
    const storedLoginStatus = localStorage.getItem('isLoggedIn');
    if (storedLoginStatus === 'true') {
      setIsLoggedIn(true);
      setUserRole(localStorage.getItem('userRole') || '');
    }
  }, []);

  const handleLogin = (role) => {
    setIsLoggedIn(true);
    setUserRole(role);
    localStorage.setItem('isLoggedIn', 'true');
    localStorage.setItem('userRole', role);
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
    localStorage.removeItem('token');
    localStorage.removeItem('userRole');
    localStorage.setItem('isLoggedIn', 'false');
  };

  return (
    <Router>
        <Scrollbars style={{ width: '100%', height: '100vh' }}>
      <div className="App">
        <Header isLoggedIn={isLoggedIn} onLogout={handleLogout} organizationId={organizationId} />

        <Routes>
          <Route path="/" element={<Navigate to="/user-login" />} />
          <Route path="/super-admin-login" element={<SuperAdminLoginForm onLogin={() => handleLogin('SuperAdmin')} />} />
          <Route path="/create-company" element={<OrganizationSignup onLogin={() => handleLogin('SuperAdmin')} setCompanyName={setOrganizationName} />} />
          <Route path="/user-login" element={<LoginForm onLogin={(role) => handleLogin(role)} />} />
          <Route path="/create-password" element={<CreatePassword onLogin={(role) => handleLogin(role)} />} />
          <Route path="/company-name" element={isLoggedIn ? <UpdateOrganization setCompanyName={setOrganizationName} /> : <Navigate to="/super-admin-login" />} />
          <Route path="/super-admin" element={isLoggedIn && userRole === 'SuperAdmin' ? <SuperAdminDashboard /> : <Navigate to="/super-admin-login" />} />
          <Route path="/add-admin" element={isLoggedIn && userRole === 'SuperAdmin' ? <AddAdmin /> : <Navigate to="/super-admin-login" />} />
          <Route path="/admin" element={isLoggedIn && userRole === 'Admin' ? <AdminDashboard /> : <Navigate to="/user-login" />} />
          <Route path="/create-employee" element={isLoggedIn && userRole === 'Admin' ? <AddEmployee /> : <Navigate to="/user-login" />} />
          <Route path="/employee-feedback" element={<EmployeeFeedback />} />
          <Route path="/employee-feedback/employee" element={<EmployeeFeedback />} />
          <Route path="/auditor-feedback-check" element={isLoggedIn && userRole === 'Auditor' ? <AuditorFeedBackCheck /> : <Navigate to="/user-login" />} />
          <Route path="/update-managers/:id" element={<UpdateManagers />} /> 
          <Route path="/update-employee/:id" element={isLoggedIn && userRole === 'Admin' ? <UpdateEmployee /> : <Navigate to="/user-login" />} />
          <Route
            path="/reset-password/masterData/forgetPasswordEmail"
            element={
              (userRole !== 'Super Admin' && localStorage.getItem('resetUserRole') !== 'Super Admin') ? (
                <UserResetPassword />
              ) : (
                <SuperAdminResetPassword />
              )
            }
          />
          <Route path="/manager-dashboard/" element={isLoggedIn && userRole ==='Manager' ? <Managerdashboard /> : <Navigate to="/user-login" /> } />
        </Routes>
      </div>
      </Scrollbars>
    </Router>
  );
}

export default App;
