import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TextField, Button, InputAdornment } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './UserResetPassword.css';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

const UserResetPassword = () => {
  const [email, setEmail] = useState('');
  const [newUserPassword, setNewUserPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showNewUserPassword, setShowNewUserPassword] = useState(false); 
  const [showConfirmPassword, setShowConfirmPassword] = useState(false); 
  const navigate = useNavigate();

  const handleUserResetPassword = async () => {
    if (!email || !newUserPassword || !confirmPassword) {
      toast.error('Please fill in all fields.');
      return;
    }
    if (newUserPassword !== confirmPassword) {
      toast.error('Passwords do not match.');
      return;
    }
  
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/employeeRoutes/updateEmployeePassword`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email,
          password: newUserPassword,
          confirmPassword,
        }),
      });
  
      const data = await response.json();
      if (response.ok) {
        toast.success('Password has been reset successfully.');
        
        if (data.role) {
          localStorage.setItem('userRole', data.role);
        }
  
        navigate('/user-login');
      } else {
        toast.error(data.message || 'Failed to reset password.');
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error('An error occurred while resetting the password.');
    }
  };

  const toggleNewUserPasswordVisibility = () => {
    setShowNewUserPassword((prevShowPassword) => !prevShowPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword((prevShowPassword) => !prevShowPassword);
  };

  return (
    <div className="user-reset-password-container">
      <ToastContainer />
      <div className="user-reset-password-left">
        <h3 className="user-reset-password-title">Reset Your Password</h3>
        <div className="user-reset-password-input-fields">
          <TextField
            className="user-reset-password-input"
            label={<span>Enter Your Email <span className="required-asterisk">*</span></span>}
            type="email"
            fullWidth
            value={email}
            onChange={(e) => setEmail(e.target.value)} 
          />
          <TextField
            className="user-reset-password-input"
            label={<span>New Password <span className="required-asterisk">*</span></span>}
            type={showNewUserPassword ? 'text' : 'password'} 
            fullWidth
            value={newUserPassword}
            onChange={(e) => setNewUserPassword(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <span className="login-eye-icon" onClick={toggleNewUserPasswordVisibility}>
                    {showNewUserPassword ? <FaEyeSlash /> : <FaEye />}
                  </span>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            className="user-reset-password-input"
            label={<span>Confirm Password <span className="required-asterisk">*</span></span>}
            type={showConfirmPassword ? 'text' : 'password'} 
            fullWidth
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <span className="login-eye-icon" onClick={toggleConfirmPasswordVisibility}>
                    {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                  </span>
                </InputAdornment>
              ),
            }}
          />
        </div>
        <Button
          className="user-reset-password-button"
          variant="contained"
          color="primary"
          onClick={handleUserResetPassword}
        >
          Reset Password
        </Button>
      </div>
    </div>
  );
};

export default UserResetPassword;
